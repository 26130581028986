.Column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.profilepic {
  height: 140px;
  width: 140px;
  border-radius: 70px;
  object-fit: cover;
}

.Container {
  padding: 0;
  height: 100vh;
  width: 100%;
  background-color: #f5f5f5;
}

.Inner_Container {
  height: 90%;
  width: 500px;
  background-color: #ffffff;
  border-radius: 16px;
  border: 1px solid #e6e6e6;
  padding-inline: 30px;
  padding-block: 30px;
}

.HeaderTxt {
  font-size: 32px;
  font-weight: 700;
  color: #262626;
}

.ImageTxt {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.profileScroll::-webkit-scrollbar {
  width: 0.5em;
}
.profileScroll::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 100vw;
  margin-block: 0.5em;
}
.profileScroll::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 100vw;
  border: 0.125em solid #e6e6e6;
}
