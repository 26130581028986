@use '../abstracts/colors' as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.analytics_container {
    @extend .Column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;

    @include b.breakpoint(large){
        padding-inline: f.rem(40);
        padding-block: f.rem(20);
        justify-content: space-between;
    }

    &__header {
        @extend .Row;
        width: 100%;
    }
}

.detailed_analytics_container {
    display: none;

    @include b.breakpoint(large){
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: colors.$white01;
        border: 1px solid colors.$borderwhite;
        border-radius: f.rem(16);
        margin-block: f.rem(16);
        padding: f.rem(16);
    }
}

.analytics_details_container {
    @extend .Row;
    width: 100%;
    justify-content: flex-start;
    // background-color: crimson;

    @include b.breakpoint(large){
        flex-direction: column;
        justify-content: flex-start;
    }
}

.stats_card_container {
    @extend .Column;
    // background-color: cornflowerblue;
    width: 50%;
    justify-content: space-evenly;
    align-items: flex-start;

    @include b.breakpoint(large){
        flex-direction: row;
        width: 100%;
        margin-block: f.rem(16);
        padding-inline: f.rem(16);
    }
}

.line_graph_container {
    // background-color: chocolate;
    // height: auto;
    height: 50%;
    padding-block: f.rem(16);
    // margin-block: f.rem(20);
    width: 100%;
    transform: rotate(90deg);

    @include b.breakpoint(large){
        transform: none;
        height: f.rem(400);
        width: 100%;
    }
}

.stat_card_item_container {
    margin-block: f.rem(20);
    // background-color: burlywood;
    width: 70%;

    @include b.breakpoint(large){
        margin-block: f.rem(8);
        width: 25%;
    }
}

.stat_card_percentage {
    font-size: f.rem(24);

    @include b.breakpoint(large){
        font-size: f.rem(32);
    }
}

.cp_header_container {
    @extend .Column;
    width: 100%;
    padding-inline: f.rem(8);

    @include b.breakpoint(large){
        padding-inline: f.rem(16);
        flex-direction: row;
    }

    &__title {
        @extend .Row;
        // width: 100%;
        margin-block: f.rem(4);

        @include b.breakpoint-down(medium){
            width: 100%;
        }
    }

    &__select {
        @extend .Row;
        width: 50%;
        margin-block: f.rem(4);

        @include b.breakpoint-down(medium){
            flex-direction: column;
            width: 100%;
        }

    }
}