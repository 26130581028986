body {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.verythinscrollnew::-webkit-scrollbar {
  width: 0.5em;
  background-color: #fafafa;
}
.verythinscrollnew::-webkit-scrollbar-track {
  background-color: #fafafa;
}
.verythinscrollnew::-webkit-scrollbar-thumb {
  /* background:  #666666; */
  background: #e6e6e6;
  border-radius: 100vw;
  border: 0.125em solid #f5f5f5;
}

.my-modal {
  max-width: 1012px;
  max-height: 671px;
  border-radius: 0px;
}
.btn-close {
  background-image: url('../../Images/contactsNew/CMClose.png');
  opacity: 1;
}
