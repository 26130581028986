@use "../abstracts/colors" as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ic_new_modal_container {
    @extend .Column;
    height: 80vh;
    width: min(90vw, f.rem(1012));
    background-color: colors.$white01;
    border-radius: f.rem(16);
    z-index: 0;
    border: 1px solid colors.$borderwhite;
    // padding-inline: f.rem(20);

    @include b.breakpoint(large){
        padding-inline: f.rem(20);
    }

    &__header {
        @extend .Row;
        width: 100%;
        padding-block: f.rem(16);
        border-bottom: 1px solid colors.$borderwhite;
        padding-inline: f.rem(20);

        @include b.breakpoint(large){
            padding-inline: 0;
        }

        h2 {
            color: colors.$black01;
        }

        img {
            height: f.rem(24);
            width: f.rem(24);
            object-fit: contain;
            cursor: pointer;
        }
    }

    &__body {
        @extend .Row;
        width: 100%;
        flex: 1;
        overflow-y: auto;
        margin-block: f.rem(16);

        .info_50 {
            display: none;

            @include b.breakpoint(large){
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 50%;
                margin-block: f.rem(16);
                height: 100%;
            }
            
        }

        .upload_50 {
            @extend .Column;
            justify-content: start;
            width: 100%;
            height: 100%;
            padding-inline: f.rem(16);

            @include b.breakpoint(large){
                width: 50%;
                margin-block: f.rem(16);
                
            }
        }
    }
}