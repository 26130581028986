.dropdown .dropdown-menu {
  max-height: 250px;
  overflow: auto;
}

.hovercustom:hover {
  box-shadow: 1px 1px 10px #b3b3b3;
}

.table-scheduler th,
.table-scheduler td {
  border-right: 1px solid rgb(179, 179, 179);
}

.paginated {}

.custom-select-container {
  display: flex;
  align-items: center;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  padding: 0 8px;
  /* Adjusted padding */
  height: 40px;
  font-size: 12px;
  color: #555555;
  transition: border-color 0.3s ease;
  position: relative;
  /* Added for positioning */
}

.focused {
  border-color: #333333;
}

.sort-label {
  color: #555555;
  font-weight: bold;
  font-size: 12px;
  margin-right: 8px;
  /* Added margin to separate from select */
  line-height: 40px;
  /* Align label vertically */
}

.custom-select {
  border: none;
  box-shadow: none;
  outline: none;
  flex-grow: 1;
  background-color: transparent;
  color: #b5b1b1;
  font-size: 12px;
  padding: 0;
  /* Remove padding to fit properly */
}

.MuiSelect-root {
  padding-left: 10px;
  /* Ensure proper padding inside select */
}

.CampiagnFilter {
  position: 'relative';
  margin-left: 6vw;
  margin-top: 38vh;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 6;
  border-radius: 10px;
}