.roundedBack {
  height: 10px;
  width: 10px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: calc(100vw - 483px);
}

.editSideBarDiv {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background: var(--whites-white-01, #fff);
  box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.25);
  width: min(100%, 483px);
  padding: 17px 25px;
  overflow-y: auto;
  scrollbar-width: thin;

  .mainHeader {
    font-weight: 700;
    font-size: 1.2rem;
  }

  .saveButton {
    appearance: none;
    padding: 8px 32px;
    border-radius: var(--corner-radius-corner-radius-xs, 8px);
    background: var(--purple-purple-dark, #8e53f7);
    color: #ffffff;
    border: none;
  }

  .inputHeader {
    font-weight: 700;
    font-size: 0.9rem;
  }

  .inputBar {
    border-radius: 8px;
    border: 1px solid var(--border-color-border-medium, #ccc);
    padding: 8px;
  }

  .colorizedLabels {
    border-radius: 7px;
    border: 1px solid var(--links-blue, #36c);
    padding: 4px 16px;
    width: max-content;
    font-size: 0.9rem;
  }

  .radialButton {
    border-radius: 44px;
    background: var(--whites-white-04, #f0f0f0);
    width: max-content;
    font-size: 0.9rem;
    padding: 4px 8px;
  }
}
