@use "../abstracts/colors" as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.delete_modal {
    @extend .Column;
    // justify-content: center;
    max-height: 80vh;
    width: min(90vw, f.rem(500));
    background-color: colors.$white01;
    border-radius: f.rem(16);
    z-index: 0;
    border: 1px solid colors.$borderwhite;
    padding-inline: f.rem(20);
    padding-block-start: f.rem(20);
    padding-block-end: f.rem(30);
    overflow-y: auto;

    &__header {
        @extend .Row;
        justify-content: center;
        width: 100%;
        padding-block-end: f.rem(16);

        h3 {
            color: colors.$black01;
        }

        img {
            height: f.rem(24);
            width: f.rem(24);
            object-fit: contain;
            cursor: pointer;
        }
    }

    p {
        color: colors.$black01;
        width: 100%;
        margin-block: f.rem(16);
    }

    &__button_row {
        @extend .Row;
        width: 100%;
    }
}