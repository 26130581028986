@use "../abstracts/colors" as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ANA_modal_container {
    @extend .Column;
    height: min(70vh,f.rem(640));
    width: min(90vw,f.rem(454));
    background-color: colors.$white01;
    border-radius: f.rem(16);
    z-index: 0;
    border: 1px solid colors.$borderwhite;
    padding-inline: f.rem(40);
    padding-block: f.rem(24);

    &__header {
        @extend .Row;
        width: 100%;
        margin-block-end: f.rem(20);

        h3 {
            color: colors.$black01;
            width: 100%;
            text-align: center;
        }
    }

    &__body {
        @extend .Column;
        justify-content: flex-start;
        width: 100%;
        margin-block-end: f.rem(20);
        padding-inline: f.rem(4);
        flex: 1;
        overflow-y: auto;

        p {
            color: colors.$greys01;
            width: 100%;
        }
    }
}