body {
  cursor: default;
}

input[type='checkbox'] {
  accent-color: #9357ff;
}

.mailCsv {
  max-height: calc(100vh - 130px);
  width: 30vw;
  margin-top: 30%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
}

.analyticsFilterDropdown {
  position: absolute;
  margin-left: 60vw;
  margin-top: 6vh;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 6;
  width: 22vw;
}
