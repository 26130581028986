.contact-sidebar {
  position: relative;
  top: 0;
  right: 0;
  width: 380px; 
  height: 100vh; 
  background-color: white;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  overflow-y: auto; 
  overflow-x: hidden;
}

.contact-sidebar.hidden {
  transform: translateX(100%); 
}
