.Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.DarkBg {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  padding: 16px;
  width: 300px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1010;
}

.modal-header {
  font-weight: bold;
  margin-bottom: 8px;
}

.modal-select {
  width: 100%;
  margin-top: 8px;
}

.modal-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  gap: 10px;
}

.modal-button {
  width: 45%;
  height: 36px;
  border-radius: 10px;
  padding-inline: 8px;
  font-size: 14px;
  font-weight: 600;
}

.Center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
