.btncustom:focus {
  outline: none !important;
  border-color: #a470ff;
  border-width: 1;
  box-shadow: 0 0 4px #a470ff;
}

.zomm {
  transition: transform 300ms ease-in-out;
}
.zoom:hover {
  transform: scale(1.1);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
