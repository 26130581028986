@use '../abstracts/colors' as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sidemenu_lg_container {
  @extend .Column;
  position: fixed;
  height: 100%;
  width: f.rem(80); // initial width
  background-color: colors.$white01;
  border: 1px solid colors.$borderwhite;
  align-items: baseline;
  z-index: 3;
  transition:
    width 0.3s ease,
    box-shadow 0.3s ease; // Transitions for smooth width change and box-shadow

  .logo-container {
    display: flex;
    flex-direction: column; // Ensure the logo and text are stacked
    align-items: center;
    justify-content: center;
    transition: border-color 0.3s ease; // Transition for border color change
    padding-block: 1px;
    width: 100%; // Ensure the container takes full width

    .logo_img {
      height: f.rem(60);
      width: f.rem(60);
      object-fit: contain;
      padding-block: f.rem(6);
      transition: padding 1s ease, transform 1s ease
    }

    .default-logo {
      display: block;
      transition: display 1s ease;
    }

    .hover-logo {
      display: none;
      transition: display 1s ease;
    }

    p {
      display: none;
    }
  }

  &:hover {
    width: 18%; // Increase the width on hover
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); // Apply box-shadow on hover

    .sidemenu_lg_container__lower {
      justify-content: flex-start;
      margin: -4;

      .lower_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .lower_element {
        display: flex;
        justify-content: flex-start;
        padding-inline: 32px;
        padding-block: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
      }

      .lower_content p {
        display: flex;
        margin-inline-start: 7px;
      }
    }

    .logo-container {
      display: flex;
      flex-direction: column; // Ensure the logo and text are stacked
      align-items: center;
      justify-content: center;
      transition: border-color 0.3s ease; // Transition for border color change
      padding-block: 1px;
      width: 100%; // Ensure the container takes full width

      .logo_img {
        height: f.rem(60);
        width: f.rem(60);
        object-fit: contain;
        padding-block: f.rem(6);
        transition: padding 1s ease; // Transition for padding change
      }

      .default-logo {
        display: none;
      }

      .hover-logo {
        display: block;
        padding: 3%;
      }

      p {
        display: none;
      }
    }

    .logo-container .default-logo {
      display: none;
    }

    .logo-container .hover-logo {
      display: block;
      width: 100%;
      transition: display 0.3s ease;
    }

    .logo-container p {
      display: block; // Show the <p> tag when the container is hovered
      font-size: 22px;
      font-weight: 700;
      color: azure;
    }

    .inner-container {
      padding-block: 20px;
    }
  }

  &__upper {
    @extend .Column;
    justify-content: flex-start;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    margin: 0;
    flex: 1;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 1px;
    }
  }

  &__lower {
    @extend .Column;
    width: 100%;

    .lower_content {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-block: 10px;
      cursor: pointer;
    }

    .lower_content:hover {
      background-color: colors.$white03;
    }

    .lower_element p {
      display: none;
    }
  }
}

@media (max-width: 1400px) {
  .sidemenu_lg_container:hover {
    width: 20%;
  }

  .submenu_super_container {
    left: calc(10% + 4em) !important;
  }
}

@media (max-width: 1300px) {
  .sidemenu_lg_container:hover {
    width: 25%;
  }

  .submenu_super_container {
    left: calc(15% + 4em) !important;
  }
}

.menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-block: 20px;
  padding-inline: 18px;
  height: 35px;
  transition:
    justify-content 0.3s ease,
    padding-inline 0.3s ease; // Transition for smooth justify-content and padding change

  .inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-inline: 5px;
    border-radius: 8px;
    cursor: pointer;
    transition: justify-content 0.3s ease; // Transition for smooth justify-content change
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    height: 24px;
    width: 24px;
    object-fit: contain;
  }

  p {
    display: none;
    margin-left: 8px;
    transition: display 0.3s ease; // Transition for smooth display change
    font-size: 14;
    font-weight: 400;
    // font-family: 'Segoe UI';

  }

  .arrow-image {
    display: none;
  }

  &:hover {
    background-color: colors.$white03;

    .inner-container {
      background-color: colors.$white03 !important;
    }
  }

  // Adjustments based on parent width
  .sidemenu_lg_container:hover & {
    justify-content: space-between; // Adjust the flex alignment when the container is hovered
    padding-inline: 14px;

    .inner-container {
      justify-content: space-between;
    }

    p {
      display: block; // Show the text when the container is hovered
    }

    .arrow-image {
      display: block;
    }
  }
}

.tooltip-arrow {
  display: none !important;
}

.submenu_super_container {
  display: none;
  background-color: transparent;
  left: calc(11% + 4em);

  .submenu_container {
    display: block;

    .options_container:hover {
      background-color: colors.$white03;
    }
  }
}

.sidemenu_lg_sub_container:hover .submenu_super_container {
  display: block;
}