@use '../abstracts/colors' as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.spinner_loader {
  @extend .Column;
  justify-content: center;
  flex: 1;
}

.whatsapp_replies {
  @extend .Row;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  // background-color: blue;

  @include b.breakpoint(large) {
    border: 1px solid colors.$borderwhite;
    border-radius: f.rem(10);
  }

  &__wainbox_container {
    width: 100%;
    height: 100%;
    border: 1px solid colors.$borderwhite;
    background-color: colors.$white01;

    @include b.breakpoint(large) {
      width: 30%;
    }
  }

  &__wachatview_container {
    width: 100%;
    height: 100%;
    border: 1px solid colors.$borderwhite;
    background-color: colors.$white01;

    @include b.breakpoint(large) {
      width: 70%;
    }
  }
}
