@use '../abstracts/colors' as colors;
@use '../abstracts/functions' as f;
@use '../layout' as layout;

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.auth {
    @extend .Column;
    height: 100dvh;
    overflow-y: hidden;
    // flex-grow: 1;
    // width: min(100%,1440px);
    width: 100%;
    background-color: colors.$white01;

    @include layout.breakpoint(large) {
        flex-direction: row;
    }

    &__explore {
        // height: 100%;
        width: 100%;
        background-color: colors.$black01;
        // display: flex;
        // display: none;

        @include layout.breakpoint(large) {
            height: 100%;
            // display: flex;
            width: 50%;
            // background-color: colors.$black01;
        }
    }

    &__login {
        // height: 100%;
        overflow-y: hidden;
        width: 100%;
        // background-color: colors.$white01;
        // background-color: aqua;
        // display: flex;
        flex: 1;
        // height: calc( 100% - 50px );

        @include layout.breakpoint(large) {
            height: 100%;
            width: 50%;
        }
    }
}

.auth_exploretemplate {
    @extend .Column;
    padding-block: f.rem(20);
    padding-inline: f.rem(20);
    // height: 100%;
    width: 100%;

    @include layout.breakpoint(large) {
        padding-block: f.rem(30);
        padding-inline: f.rem(40);
        height: 100%;
        justify-content: center;
    }

    &__header {
        @extend .Column;
        justify-content: flex-start;
        align-items: start;
        width: 100%;

        @include layout.breakpoint(medium) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-block: f.rem(20);
        }

        .header_title {
            @extend .Row;

            img {
                height: f.rem(24);
                width: auto;
                object-fit: contain;
                @include layout.breakpoint(medium){
                    height: f.rem(32);
                }
                @include layout.breakpoint(large){
                    height: f.rem(44);
                }
            }
        }

        h3 {
            color: colors.$white01;
        }
    }

    &__video {
        display: none;

        @include layout.breakpoint(large) {
            display: flex;
            height: 50%;
            width: 100%;
            border-radius: f.rem(16);
            margin-block: f.rem(20);
        }
    }

    &__description1 {
        display: none;

        @include layout.breakpoint(large) {
            display: block;
            text-align: center;
            // background-color: chartreuse;
            margin-block: f.rem(20);

            h2 {
                color: colors.$white01;
            }

            span {
                color: colors.$primary02;
            }

        }
    }

    &__description2 {
        display: none;

        @include layout.breakpoint(large) {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            margin-block: f.rem(20);
        }

        .col_45 {
            @extend .Column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 45%;
            // background-color: burlywood;
            // margin-inline: f.rem(10);
        }

        .row_item {
            @extend .Row;
            margin-block: f.rem(8);
            align-items: flex-start;

            img {
                height: f.rem(24);
                width: f.rem(24);
                object-fit: contain;
            }

            p {
                font-weight: 600;
                color: colors.$white01;
                margin-inline-start: f.rem(8);
            }
        }
    }
}



.auth_authsignup {
    @extend .Column;
    width: 100%;
    min-width: 375px;
    // flex: 1;
    // height: calc( 100% - 8px);
    height: 100%;
    padding-inline: f.rem(20);
    padding-block: f.rem(16);
    // background-color:bisque;
    overflow-y: auto;

    @include layout.breakpoint(medium) {
        padding-inline: f.rem(40);
        padding-block: f.rem(30);
    }

    &__row1 {
        @extend .Row;
        margin-inline-end: 0;
        margin-inline-start: auto;
        text-align: center;
        // background-color: chocolate;
        margin-block: f.rem(10);

        h3 {
            color: colors.$black01;
        }

        span {
            cursor: pointer;
            color: colors.$link01;
            padding-inline: f.rem(8);
        }
    }

    &__body {
        @extend .Column;
        justify-content: center;
        width: 100%;
        flex: 1;
        // padding-block-end: f.rem(30);
        // background-color: chartreuse;

        @include layout.breakpoint(medium) {
            width: 60%;
        }

        &__row1 {
            @extend .Row;
            width: 100%;
            justify-content: flex-start;
            margin-block: f.rem(8);

            .no_credit {
                background-color: colors.$success01;
                margin-inline: f.rem(10);
                padding-block: f.rem(4);
                padding-inline: f.rem(16);
                border-radius: f.rem(8);

                p {
                    color: colors.$white01;
                    // background-color: blue;
                    margin: 0;
                }
            }

        }

        p {
            width: 100%;
            color: colors.$greys01;
            margin-block: f.rem(8);
        }

        &__input_container {
            margin-block: f.rem(12);
            width: 100%;

            h4 {
                margin-block-end: f.rem(6);
                color: colors.$black01;
            }
        }

        &__button_container {
            @extend .Column;
            margin-block: f.rem(16);
            width: 100%;
            // background-color:brown;

            .row_or {
                @extend .Row;
                width: 100%;
                margin-block: f.rem(16);

                &__line {
                    width: 45%;
                    height: f.rem(1);
                    background-color: colors.$greys02;
                }

            }
        }

    }
}

.auth_authlogin {
    @extend .Column;
    width: 100%;
    height: 100%;
    min-width: 280px;
    padding-inline: f.rem(20);
    padding-block: f.rem(16);
    overflow-y: auto;
    //background-color: aqua;

    @include layout.breakpoint(medium) {
        padding-inline: f.rem(40);
        padding-block: f.rem(30);
    }

    &__body {
        @extend .Column;
        flex: 1;
        justify-content: center;
        width: 100%;
        //background-color: burlywood;

        @include layout.breakpoint(medium) {
            width: 60%;
            margin: 2%;
        }

        .row_1 {
            @extend .Row;
            margin-block: f.rem(16);
            width: 100%;
            // background-color: brown;

            h2 {
                color: colors.$black01;
            }

            p {
                color: colors.$link01;
                cursor: pointer;
            }
        }

        .row_2 {
            @extend .Row;
            width: 100%;
            margin-block: f.rem(16);
            padding-inline: f.rem(30);
            justify-content: center;
            // background-color: chartreuse;

            .formcheck_style {
                margin-inline: f.rem(16);
                font-size: f.rem(16);
                font-weight: 600;
                color: colors.$black01;
            }
        }

        .body_form {
            width: 100%;
            // background-color: chocolate;
            margin-block: f.rem(16);

            .form_container {
                @extend .Column;
                width: 100%;
                // background-color: blueviolet;
                margin-block: f.rem(8);

                .input_container {
                    margin-block: f.rem(16);
                    width: 100%;

                    h4 {
                        color: colors.$black01;
                        margin-block-end: f.rem(8);
                    }
                }

                .send_otp_container {
                    @extend .Column;
                    width: 100%;
                    // background-color: coral;

                    &__sub {
                        @extend .Row;
                        width: 100%;
                        margin-block: f.rem(8);

                        .otp_sent_to {
                            color: colors.$greys01;
                        }

                        .incorrect_otp {
                            color: colors.$failure03;
                        }
                    }

                }
            }

            &__button_container {
                @extend .Column;
                margin-block: f.rem(16);
                width: 100%;
                // background-color:brown;

                .row_or {
                    @extend .Row;
                    width: 100%;
                    margin-block: f.rem(16);

                    &__line {
                        width: 45%;
                        height: f.rem(1);
                        background-color: colors.$greys02;
                    }

                }

                .start_trial {
                    @extend .Row;
                    justify-content: center;
                    margin-block-start: f.rem(16);

                    p {
                        color: colors.$black01;

                        span {
                            cursor: pointer;
                            color: colors.$link01;
                        }
                    }
                }
            }
        }

    }
}