.label-lister{
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
  span{
    background: #e8e8e8;
    font-size: .75rem;
    padding: 6px 16px;
    border-radius: 3px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
.formedExistance{
  //border-radius: 30px;
  //background: red;
  height: 90px;
  border-radius: 0;
}
.restylingLabels{
  border-radius: 20px;
  border: none;
  //height:20px;
  padding:0;
  margin-bottom: 40px;
}

