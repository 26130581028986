@use '../abstracts/colors' as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.CheerioBtn:hover {
  // background-image: linear-gradient(rgb(0 0 0/20%) 0 0);
  transform: scale(1.05);
  transition: 0.2s;
}

.shadow_on_hover:hover {
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.15);
}

.creator {
  height: 100dvh;
  width: 100%;
  @extend .Column;
  background-color: black;
  overflow: hidden;

  &__body {
    height: calc(100% - f.rem(50));
    // height: 100%;
    width: 100%;
    background-color: colors.$accent03;

    @include b.breakpoint(medium) {
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: space-between;
      align-items: center;
    }

    &__container {
      @extend .Column;
      justify-content: flex-start;
      margin-left: f.rem(80); // Initial fixed width of the side menu

      // width: 100%;
      height: 100%;
      overflow-y: auto;
      background-color: colors.$white02;
      // padding-block: f.rem(10);
      // padding-inline: f.rem(16);
      // background-color:chocolate;

      .welcome_row {
        @extend .Row;
        width: 100%;
        margin-block: f.rem(8);
        padding-inline: f.rem(16);
        padding-block: f.rem(8);
        background-color: white;
        z-index: 2;
        // margin-block-end: f.rem(10);
        // background-color: blueviolet;

        // @include b.breakpoint(medium){
        //     width: 60%;
        // }

        @include b.breakpoint(large) {
          padding-inline: f.rem(32);
          padding-block: f.rem(12);
          margin-block: f.rem(12);
        }

        h2 {
          color: colors.$black01;
          margin-inline-end: f.rem(8);
        }
      }

      .content_container_new {
        @extend .Column;
        width: 100%;
        justify-content: flex-start;
        margin-block: f.rem(8);
        padding-inline: f.rem(16);
        // background-color: burlywood;

        @include b.breakpoint(large) {
          padding-inline: f.rem(32);
          margin-block-end: f.rem(16);
        }

        &__subheader {
          @extend .Row;
          width: 100%;
          margin-block: f.rem(24);

          h3 {
            color: colors.$black01;
          }
        }

        &__row1 {
          width: 100%;
          // background-color: chartreuse;
          @extend .Column;
          // align-items: stretch;
          margin-block-end: f.rem(12);

          @include b.breakpoint(large) {
            flex-direction: row;
            align-items: stretch;
            margin-block-end: f.rem(24);
          }

          .premiumRow {
            width: 100%;
            @extend .Column;
            align-items: stretch;
            align-self: flex-start;
            // margin-inline-end: f.rem(12);

            @include b.breakpoint(large) {
              width: 70%;
              flex-direction: row;
              align-items: stretch;
              align-self: flex-start;
              margin-inline-end: f.rem(12);
            }

            .premium_tile_container {
              @extend .Column;
              width: 100%;

              @include b.breakpoint(large) {
                width: 50%;
              }

              h3 {
                color: colors.$black01;
                width: 100%;
              }

              p {
                color: colors.$greys01;
                width: 100%;
              }
            }
          }

          .tile {
            background-color: colors.$white01;
            border: 1px solid #cccccc;
            border-radius: f.rem(16);
            padding-block: f.rem(20);
            padding-inline: f.rem(24);
          }

          .test_container {
            width: 100%;
            @extend .Column;
            margin-block-end: f.rem(12);
            align-items: stretch;

            @include b.breakpoint(large) {
              width: 100%;
              flex-direction: row;
              margin-inline-end: f.rem(12);
              margin-block-end: 0;
              align-items: stretch;
            }

            &__left {
              width: 100%;
              @extend .Column;
              // background-color: aquamarine;
              align-items: flex-start;
              margin-block-end: f.rem(16);

              @include b.breakpoint(large) {
                flex: 5;
                margin-block-end: 0;
              }

              h3 {
                color: colors.$black01;
              }

              p {
                color: colors.$greys01;
              }

              h4 {
                color: colors.$black01;

                span {
                  color: colors.$success03;
                }
              }
            }

            &__right {
              width: 100%;
              // margin-block-end: f.rem(12);
              @extend .Column;
              // background-color: blue;

              @include b.breakpoint(large) {
                flex: 3;
                margin-block-end: 0;
              }
            }
          }

          .credits_container {
            width: 100%;
            @extend .Column;
            background-color: #fff5e7;

            @include b.breakpoint(large) {
              width: 30%;
              margin-inline-start: f.rem(12);
            }

            &__row {
              @extend .Row;
              width: 100%;
              // background-color:#FFB1A3;

              h3 {
                color: colors.$black01;
              }

              p {
                color: colors.$black01;
              }

              .bold {
                font-size: f.rem(24);
                font-weight: f.rem(600);
              }
            }

            &__divider {
              margin-block: f.rem(10);
              height: f.rem(1);
              width: calc(100% + f.rem(48));
              background-color: #e5dcd0;
            }
          }
        }

        &__accordian {
          margin-block-end: f.rem(24);
          width: 100%;
          // background-color: #ff9f1b;

          .customAccordian {
            // background-color: colors.$white03;
            background-color: #fafbff;
            border: 1px solid #f0f0f0;
            border-radius: f.rem(8);
            box-shadow: none;
          }
        }

        &__master_row2 {
          width: 100%;
          @extend .Column;
          // align-items: stretch;
          // background-color: brown;
          margin-block-end: f.rem(24);

          @include b.breakpoint(large) {
            flex-direction: row;
            align-items: stretch;
          }

          &__row2 {
            width: 100%;
            @extend .Column;
            align-items: stretch;
            // height: f.rem(200);
            // background-color:#ff9f1b;
            // align-self: flex-start;
            // margin-inline-end: f.rem(12);

            @include b.breakpoint(large) {
              width: 70%;
              flex-direction: row;
              margin-inline-end: f.rem(12);
            }

            .tile {
              background-color: colors.$white01;
              border: 1px solid #cccccc;
              border-radius: f.rem(16);
              padding-block: f.rem(20);
              padding-inline: f.rem(24);
            }

            .video_container {
              width: 100%;
              @extend .Column;
              height: 100%;
              margin-block-end: f.rem(12);

              @include b.breakpoint(large) {
                width: 50%;
                margin-inline-end: f.rem(12);
                margin-block-end: 0;
              }

              h3 {
                color: colors.$black01;
                width: 100%;
              }

              .player_container {
                flex: 1;
                @extend .Column;
                margin-block-start: f.rem(20);
                aspect-ratio: 1.785;
                width: 100%;
                height: auto;
              }
            }

            .blog_container {
              width: 100%;
              @extend .Column;
              height: 100%;
              margin-block-end: f.rem(12);

              @include b.breakpoint(large) {
                width: 50%;
                margin-inline-start: f.rem(12);
                margin-block-end: 0;
              }

              h3 {
                color: colors.$black01;
                width: 100%;
                margin-block-end: f.rem(16);
              }

              p {
                color: colors.$link01;
                width: 100%;
                margin-block: f.rem(4);
              }

              p:hover {
                text-decoration: underline;
                text-decoration-color: colors.$link01;
              }
            }

            .blog_container {
              width: 100%;
              @extend .Column;
              height: 100%;
              margin-block-end: f.rem(12);

              @include b.breakpoint(large) {
                width: 50%;
                margin-inline-start: f.rem(12);
                margin-block-end: 0;
              }

              h3 {
                color: colors.$black01;
                width: 100%;
                margin-block-end: f.rem(16);
              }

              p {
                color: colors.$link01;
                width: 100%;
                margin-block: f.rem(4);
              }

              p:hover {
                text-decoration: underline;
                text-decoration-color: colors.$link01;
              }
            }

            .quality_container {
              width: 48%;
              @extend .Column;
              height: 100%;
              margin-block-end: f.rem(0);

              @include b.breakpoint(large) {
                height: 100%;
                margin-block-end: 0;
              }

              h3 {
                color: colors.$black01;
                width: 100%;
              }

              p {
                color: colors.$greys01;
                width: 100%;
              }

              h4 {
                color: colors.$link01;
                width: 100%;
              }
            }
          }

          &__col2 {
            width: 100%;
            @extend .Column;
            // background-color: #9357ff;

            @include b.breakpoint(large) {
              width: 30%;
              margin-inline-start: f.rem(12);
            }

            .tile {
              background-color: colors.$white01;
              border: 1px solid #cccccc;
              border-radius: f.rem(16);
              padding-block: f.rem(20);
              padding-inline: f.rem(24);
            }

            .qr_tile_container {
              width: 100%;
              @extend .Column;
              // height: f.rem(150);
              margin-block-end: f.rem(12);

              @include b.breakpoint(large) {
                height: 100%;
                margin-block-end: 0;
              }

              h3 {
                color: colors.$black01;
                width: 100%;
              }

              p {
                color: colors.$greys01;
                width: 100%;
              }

              h4 {
                color: colors.$link01;
                width: 100%;
              }
            }
          }
        }

        &__cards_row {
          @extend .Row;
          justify-content: flex-start;
          margin-block-end: f.rem(24);
          width: 100%;
          height: f.rem(400);
          overflow-x: auto;

          .cardImage {
            width: f.rem(270);
            height: auto;
            object-fit: contain;
            margin-inline-end: f.rem(20);
          }

          .cardImage:hover {
            // transform: scale(1.1);
            width: f.rem(275);
            height: auto;
            object-fit: contain;
            transition: all 0.2s;
          }
        }
      }

      .content_container {
        @extend .Column;
        width: 100%;
        justify-content: flex-start;
        margin-block-end: f.rem(16);
        padding-inline: f.rem(16);
        // background-color: #FFB1A3;
        // overflow-y: auto;

        @include b.breakpoint(large) {
          flex-direction: row;
          flex: 1;
        }

        &__col_70 {
          @extend .Column;
          width: 100%;
          justify-content: flex-start;
          // background-color: #ff9f1b;

          @include b.breakpoint(large) {
            width: 70%;
            height: 100%;
            padding-inline: f.rem(16);
          }
        }

        &__col_30 {
          @extend .Column;
          width: 100%;
          justify-content: flex-start;
          // background-color: cadetblue;

          @include b.breakpoint(large) {
            width: 30%;
            height: 100%;
            padding-inline: f.rem(16);
          }
        }
      }
    }
  }
}

.progress_container {
  display: none;

  @include b.breakpoint(large) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: f.rem(16);
    margin-block: f.rem(16);
    background-color: colors.$white01;
    border: 1px solid colors.$borderwhite;
  }

  &__left_container {
    @extend .Column;
    height: 100%;
    align-items: flex-start;
    width: 60%;
    padding-block: f.rem(16);
    padding-inline-start: f.rem(20);
    margin-inline-end: f.rem(10);

    h4 {
      width: 100%;
      margin-block: f.rem(8);
      color: colors.$black01;
    }

    .row_container {
      @extend .Row;
      margin-block: f.rem(4);
      justify-content: flex-start;
      width: 100%;

      img {
        height: f.rem(20);
        width: f.rem(20);
        object-fit: contain;
        margin-inline-end: f.rem(8);
      }

      p {
        color: colors.$greys01;
      }
    }
  }

  &__right_container {
    @extend .Column;
    height: 100%;
    align-items: flex-start;
    width: 40%;
    padding-block: f.rem(16);
    padding-inline-start: f.rem(10);
    margin-inline-end: f.rem(20);

    .row_container {
      @extend .Row;
      margin-block: f.rem(4);
      width: 100%;

      p {
        width: 40%;
        color: colors.$black01;
      }
    }
  }
}

.setup_email_container {
  @extend .Column;
  width: 100%;
  background-color: #ffdcd6;
  margin-block: f.rem(16);
  border-radius: f.rem(16);
  border: 1px solid #ffb1a3;
  padding-inline: f.rem(20);
  padding-block: f.rem(10);

  @include b.breakpoint(large) {
    padding-inline: f.rem(24);
    padding-block: f.rem(20);
  }

  h4 {
    color: colors.$black01;
    width: 100%;
  }

  p {
    color: colors.$black01;
    margin-block: f.rem(10);
  }

  &__button_container {
    @extend .Column;
    width: 100%;

    @include b.breakpoint(large) {
      flex-direction: row;
      justify-content: flex-start;
    }
  }
}

.contact_campaign_container {
  @extend .Column;
  width: 100%;
  margin-block: f.rem(16);
  // background-color: chocolate;

  @include b.breakpoint(large) {
    flex-direction: row;
  }

  .contact_container {
    @extend .Column;
    height: 100%;
    width: 100%;
    border-radius: f.rem(16);
    padding-block: f.rem(10);
    padding-inline: f.rem(20);
    background-color: #d7e4ff;
    border: 1px solid #2d3f6655;
    margin-block-end: f.rem(8);

    @include b.breakpoint(large) {
      width: 50%;
      margin-inline-end: f.rem(16);
      margin-block-end: 0;
    }

    &__row {
      @extend .Row;
      width: 100%;
      margin-block: f.rem(16);

      .image_container {
        height: f.rem(50);
        width: f.rem(50);
        border-radius: f.rem(12);
        margin-inline-end: f.rem(16);
        background-color: colors.$white01;
        border: 1px solid #2d3f6655;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: f.rem(32);
          width: f.rem(32);
          object-fit: contain;
        }
      }

      img {
        height: f.rem(32);
        width: f.rem(32);
        object-fit: contain;
      }

      p {
        flex: 1;
        color: colors.$black02;
        flex-wrap: wrap;
        word-break: break-word;
      }
    }

    &__divider {
      height: f.rem(1);
      width: calc(100% + 40px);
      background-color: #2d3f6655;
    }
  }

  .campaign_container {
    @extend .Column;
    height: 100%;
    width: 100%;
    border-radius: f.rem(16);
    padding-block: f.rem(10);
    padding-inline: f.rem(20);
    background-color: #ffddd7;
    border: 1px solid #ffb1a3;
    margin-block-start: f.rem(8);

    @include b.breakpoint(large) {
      width: 50%;
      margin-inline-start: f.rem(16);
      margin-block-start: 0;
    }

    &__row {
      @extend .Row;
      width: 100%;
      margin-block: f.rem(16);

      .image_container {
        height: f.rem(50);
        width: f.rem(50);
        border-radius: f.rem(12);
        margin-inline-end: f.rem(16);
        background-color: colors.$white01;
        border: 1px solid #ffb1a3;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: f.rem(32);
          width: f.rem(32);
          object-fit: contain;
        }
      }

      p {
        flex: 1;
        color: colors.$black02;
        flex-wrap: wrap;
        word-break: break-word;
        // font-weight: 500;
      }
    }

    &__divider {
      height: f.rem(1);
      width: calc(100% + 40px);
      background-color: #ffb1a3;
    }
  }
}

.aisensy_status_container {
  @extend .Column;
  width: 100%;
  background-color: colors.$white01;
  margin-block: f.rem(8);
  margin-block-start: f.rem(16);
  border-radius: f.rem(16);
  border: 1px solid colors.$borderwhite;
  padding-inline: f.rem(20);
  padding-block: f.rem(10);
  align-items: flex-start;

  h4 {
    color: colors.$black01;
    margin-block-start: f.rem(4);
    // font-weight: 600;
  }

  span {
    color: colors.$white01;
    background-color: colors.$success03;
    border-radius: f.rem(8);
    margin-block: f.rem(8);
    padding-block: f.rem(2);
    padding-inline: f.rem(20);
    font-size: f.rem(16);
    font-weight: 400;
  }
}

// .credits_container {
//     @extend .Column;
//     width: 100%;
//     background-color: #FFF5E7;
//     margin-block: f.rem(16);
//     border-radius: f.rem(16);
//     border: 1px solid colors.$borderwhite;
//     padding-inline: f.rem(20);
//     padding-block: f.rem(10);

//     &__row {
//         @extend .Row;
//         width: 100%;
//         margin-block-start: f.rem(6);
//         margin-block-end: f.rem(16);

//         h4 {
//             color: #4C402F;
//             // font-weight: 600;
//         }

//         img {
//             height: f.rem(24);
//             width: f.rem(24);
//             object-fit: contain;
//             // border-radius: f.rem(2);
//             // border: 1px solid colors.$borderwhite;
//             cursor: pointer;
//         }
//     }

//     &__divider {
//         height: f.rem(1);
//         width: calc( 100% + 40px );
//         background-color: #E5DCD0;
//     }

//     &__row_element {
//         @extend .Row;
//         width: 100%;
//         margin-block: f.rem(8);
//         // background-color: burlywood;

//         p {
//             flex: 1;
//             color: colors.$black01;
//             // background-color: aqua;
//         }

//         span {
//             font-size: f.rem(30);
//             font-weight: 500;
//             color: #4D402F;
//             margin-inline-end: 0;
//             // background-color: #ff9f1b;
//             // margin-inline-start: auto;
//         }
//     }

// }

.creator_header {
  @extend .Row;
  width: 100%;
  height: f.rem(50);
  border-bottom: 1px solid colors.$borderwhite;
  background-color: colors.$white01;
  padding-inline: f.rem(16);

  &__sidemenu {
    @extend .Row;
    justify-content: flex-start;
    width: f.rem(240);
    // background-color: aqua;

    .sidemenu_img {
      height: f.rem(24);
      width: f.rem(24);
      object-fit: contain;
      margin-inline-end: f.rem(8);
    }

    h1 {
      // display: none;
      font-size: f.rem(20);
      color: colors.$black01;
    }
  }

  &__right_container {
    @extend .Row;
    justify-content: flex-end;
    // background-color: coral;

    h2 {
      margin-inline: f.rem(8);
      color: colors.$black01;

      @include b.breakpoint-down(medium) {
        display: none;
      }
    }

    h4 {
      margin-inline: f.rem(8);
      color: colors.$black01;

      @include b.breakpoint-down(medium) {
        display: none;
      }
    }

    .validity_container {
      @extend .Row;
      justify-content: center;
      padding-inline: f.rem(16);
      padding-block: f.rem(10);
      margin-inline: f.rem(8);
      background-color: colors.$primary01;
      border-radius: f.rem(10);

      @include b.breakpoint-down(medium) {
        display: none;
      }

      h4 {
        color: colors.$primary02;
      }
    }

    .invalid_text {
      color: colors.$failure02;
    }

    img {
      height: f.rem(32);
      width: f.rem(32);
      object-fit: contain;
      border-radius: 50%;
      cursor: pointer;

      // border gradient
      border: double 3px transparent;
      border-radius: 24px;
      background-image: linear-gradient(white, white),
        radial-gradient(circle at top, #9357ff, #ff9f1b);
      background-origin: border-box;
      background-clip: padding-box, border-box;
    }
  }
}

.creator_sidemenu {
  @extend .Column;
  height: calc(100% - f.rem(50));
  transition: 'width 200ms ease';
  padding-block: f.rem(4);
  background-color: colors.$white01;
  border: 1px solid colors.$borderwhite;
  z-index: 3;
  top: 50;

  @include b.breakpoint(medium) {
    position: relative;
    height: 100%;
    padding-block: f.rem(16);
    // z-index: 0;
  }

  &__top {
    width: 100%;
    // padding-inline: f.rem(8);
    // background-color: cadetblue;

    .web_only {
      width: 100%;
      // background-color: brown;
      margin-block-start: f.rem(20);

      &__title {
        margin-block: f.rem(16);
        width: 100%;
      }

      h4 {
        color: colors.$black01;
      }
    }
  }

  &__bottom {
    width: 100%;
    // background-color: chocolate;

    .raise_ticket {
      width: 100%;
      margin-block-end: f.rem(16);
      cursor: pointer;

      h4 {
        text-align: center;
        color: colors.$black02;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .creator_sidemenu {
    position: fixed;
  }

  .creator__body__container {
    margin-left: 0;
  }
}

.sidemenu_item_2 {
  @extend .Row;
  margin-block: f.rem(8);
  width: 100%;
  border-radius: f.rem(4);
  background-color: colors.$primary01;
  padding-inline: f.rem(8);
  padding-block: f.rem(8);
  cursor: pointer;

  &__icon {
    height: f.rem(24);
    width: f.rem(24);
    object-fit: contain;
  }

  .badge {
    margin-inline-end: 0;
    margin-inline-start: auto;
  }
}

.qrtile_container {
  @extend .Column;
  width: 100%;
  background-color: colors.$white01;
  margin-block: f.rem(16);
  border-radius: f.rem(16);
  border: 1px solid colors.$borderwhite;
  border-width: f.rem(1);
  padding-inline: f.rem(20);
  padding-block: f.rem(16);

  h4 {
    color: colors.$black01;
    width: 100%;
  }

  &__row {
    @extend .Row;
    justify-content: flex-start;
    width: 100%;
    margin-block-start: f.rem(16);
    cursor: pointer;

    p {
      margin-inline-start: f.rem(8);
      color: colors.$link01;
    }
  }
}

.broadcast_container {
  @extend .Column;
  width: 100%;
  border: 1px solid colors.$borderwhite;
  border-radius: f.rem(16);
  margin-block: f.rem(16);
  padding-inline: f.rem(20);
  padding-block: f.rem(16);
  background-color: colors.$white01;

  &__header {
    @extend .Row;
    width: 100%;

    h4 {
      color: colors.$black01;
    }

    p {
      color: colors.$black01;

      span {
        color: colors.$success03;
      }
    }
  }

  &__description {
    margin-block: f.rem(8);
    width: 100%;

    p {
      width: 100%;
      color: colors.$greys01;
    }
  }

  &__details {
    margin-block: f.rem(8);
    width: 100%;

    p {
      width: 100%;
      color: colors.$black01;

      span {
        color: colors.$greys01;
        font-weight: 400;
      }
    }
  }

  &__input {
    @extend .Column;
    width: 100%;

    @include b.breakpoint(large) {
      flex-direction: row;
    }

    .form_input_container {
      margin-block: f.rem(4);
      width: 100%;

      @include b.breakpoint(large) {
        width: 30%;
      }
    }
  }

  &__footer {
    @extend .Column;
    width: 100%;
    margin-block: f.rem(10);

    @include b.breakpoint(large) {
      flex-direction: row;
    }

    .footer_left {
      margin-block: f.rem(8);
      width: 100%;

      @include b.breakpoint(large) {
        width: 50%;
      }

      p {
        width: 100%;
        color: colors.$greys01;
      }
    }

    .footer_right {
      @extend .Column;
      width: 100%;

      @include b.breakpoint(large) {
        flex-direction: row;
        justify-content: space-evenly;
        width: 50%;
      }
    }
  }
}