.bg-customcolor {
  background-color: #9357ff !important;
}

.trialbtn:hover {
  background-color: white;
}

.trialbtn:focus {
  background-color: white;
}

.btncustom:focus {
  outline: none !important;
  border-color: #a470ff;
  border-width: 1;
  box-shadow: 0 0 4px #a470ff;
}
.btncustom:focus:active {
  box-shadow: 0 0 4px #a470ff;
}

.bordergradient {
  border: double 3px transparent;
  border-radius: 24px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top, #9357ff, #ff9f1b);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

span.badge {
  padding: 6px;
  margin-bottom: 1rem;
  width: 5rem;
  margin-top: 5px;
}

span.badge-success {
  color: white;
  background: green;
}
