.btncustom:focus {
  outline: none !important;
  border-color: #a470ff;
  border-width: 1;
  box-shadow: 0 0 4px #a470ff;
}

.btncustom2:hover {
  background-color: white;
}
.btncustom2:focus {
  background-color: white;
}

.verythinscroll::-webkit-scrollbar {
  width: 0.5em;
}
