.hr {
  width: auto;
  margin-left: -15px;
  margin-right: -15px;
}

.container {
  max-width: 100% !important;
  /*Set your own width %; */
}

.modal-height {
  max-height: calc(100vh - 153px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: white;
  scrollbar-gutter: stable;
  border-radius: 16px;
}

.default-replies-modals {
  overflow: hidden;
  max-height: calc(100vh - 150px);
  margin-top: 35%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
}

/* 
.modal-body{
   border:"2px solid blue"
} */
.chatflow-modal-height {
  max-height: calc(100vh - 150px);
  margin-top: 35%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
}

.Train-chatbot {
  max-height: calc(100vh - 150px);
  width: 60%;
  margin-top: 37%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  padding: 10px;
}

.btnHover {
  border-radius: 16px;
  transition: all 0.3s ease;
}

.btnHover:hover {
  border: 1px solid #8E53F7;
  border-radius: 16px;
}