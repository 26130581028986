.commaSeperator {
  width: 100%;
  .magnet-search{
    border-radius: 8px;
    border: 1px solid var(--border, #CCC);
    background: var(--whites-white-01, #FFF);
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 8px;
    gap: 8px;
    margin-bottom: 20px;
    input {
      appearance: none;
      border: none;
      outline: none;
    }
  }
  .tags-container {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
    .tag-boxs {
      background: var(--whites-white-04, #F0F0F0);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.24);
      width: max-content;
      display: flex;
      align-items: center;
      gap: 10px;
      border-radius: 7px;
      border: 1px solid var(--links-blue, #36C);
      padding: 4px 16px;
      width: max-content;
      font-size: .9rem;
      background: none;
    }
  }
  .tag-header {
    font-weight: 500;
    font-size: .9rem;
    margin-top: 10px;
  }
  .editor-form {
    border-radius: 8px;
    border: 1px solid var(--border, #CCC);
    background: var(--whites-white-01, #FFF);
    width: 100%;
    padding: 8px 8px;
  }
}
