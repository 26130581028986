.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

p,
h3,
h2 {
    margin-block: 0;
    padding-block: 0;
}

.safearea {
    width: 1200px;
}

.custom_button {
    @extend .Row;
    justify-content: center;
    margin-inline: 16px;
    padding-block: 8px;
    padding-inline: 16px;
    background-color: #9357FF;
    border: 1px solid #9357FF;
    border-radius: 6px;
    width: auto;
    cursor: pointer;
}

.custom_button_white {
    @extend .Row;
    justify-content: center;
    margin-inline: 16px;
    padding-block: 8px;
    padding-inline: 16px;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 6px;
    width: auto;
    cursor: pointer;
}

.font14 {
    font-size: 16px;
    font-weight: 400;
    color: black;
}

.font16 {
    font-size: 16px;
    font-weight: 400;
    color: black;
}

.font18 {
    font-size: 18px;
    font-weight: 700;
    color: black;
}

.font20 {
    font-size: 20px;
    font-weight: 600;
    color: black;
}

.font26 {
    font-size: 26px;
    font-weight: 700;
    color: black;
}

.font32 {
    font-size: 32px;
    font-weight: 800;
    color: black;
}

.black01 {
    color: black;
}

.white01 {
    color: white;
}

.primary01 {
    color: #9357FF
}

.main_container {
    @extend .Column;
    justify-content: start;
    width: 100%;
    min-height: 100dvh;
    flex: 1;
    background-repeat: repeat-y;
    background: rgb(244, 255, 121);
    background: linear-gradient(90deg, rgba(244, 255, 121, 0.5001460056678921) 0%, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 1) 75%, rgba(255, 169, 121, 0.5029471261160714) 100%);

    .header_container {
        @extend .Row;
        justify-content: center;
        width: 100%;
        height: 76px;
        background-color: white;
        border-bottom: 1px solid #CCCCCC;

        .visible_area_xl {
            @extend .Row;
            width: 1200px;

            .logo_image {
                height: 33px;
                width: auto;
                object-fit: contain;
            }

        }
    }

    .section_container {
        margin-block-start: 200px;
        @extend .Column;
        justify-content: flex-start;
        // background-color: brown;

        .phone_input_container {
            @extend .Row;
            justify-content: center;
            align-items: stretch;
            // width: '100%';
            margin-block: 20px;
            background-color: white;
            border: 1px solid #CCCCCC;
            padding-block: 8px;
            padding-inline: 12px;
            border-radius: 10px;
        }
    }

    .footer_container {
        @extend .Column;
        justify-content: flex-start;
        margin-block-start: 24px;
        width: 100%;
        background-color: #32195F;
        padding-block: 30px;
    }
}


/* InfiniteScroller.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* InfiniteScroller.css */
.scroller {
    display: flex;
    white-space: nowrap;
    align-items: center;
    overflow-x: scroll;
    /* Allow horizontal scrolling */
    scrollbar-width: none;
    /* Hide scrollbar in Firefox */
}

.scroller::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar in WebKit-based browsers (Chrome, Safari, etc.) */
}

.scroller-wrapper {
    margin-block: 16px;
    overflow: hidden;
    width: 100%;
    /* Adjust as needed */
}

.card {
    flex-shrink: 0;
    // width: 200px; /* Adjust as needed */
    margin-inline: 30px;
}

.card img {
    height: 100px;
    width: 200px;
    object-fit: contain;
    // display: block;
}

.component1_container {
    flex: 0 0 50%;
    // width: 50%;
    padding: 10px;

    .component1_card {
        height: 100%;
        border: 1px solid #CCCCCC;
        border-radius: 20px;
        padding: 20px;
        @extend .Column;
        justify-content: start;
        width: 100%;
        background-color: white;

        .component1_image {
            width: 80%;
            height: 310px;
            object-fit: contain;
            margin-block-end: 16px;
        }

        .component1_text {
            width: 100%;
        }
    }
}

.component1_div {
    @extend .Row;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-block: 20px;

}

.primarybanner_container {
    width: 100%;
    background-color: #9357FF;
    margin-block-start: 200px;
    @extend .Row;
    justify-content: center;

    .primarybanner_safearea {
        width: 1200px;
        padding-block: 30px;
        // background-color: aqua;
        @extend .Row;

        .primarybanner_buttons_container {
            @extend .Row;
            justify-content: flex-end;
        }
    }
}

.component2_container {
    @extend .Column;
    justify-content: flex-start;
    flex: 1;
    padding: 16px;
    margin-inline: 20px;

    .component2_image {
        width: 80%;
        height: auto;
        object-fit: contain;
        margin-block-end: 10px;
    }

    .component2_text {
        width: 100%;
        text-align: center;
    }
}

.component2_div {
    width: 100%;
    @extend .Row;
    align-items: stretch;
}

.component3_container {
    @extend .Column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    margin-inline: 20px;
    padding: 16px;
    border: 1px solid #CCCCCC;
    border-radius: 12px;
}

.component3_div {
    width: 100%;
    @extend .Row;
    align-items: stretch;
}

.component4_container {
    width: 100%;
    @extend .Row;
    align-items: stretch;

    .component4_image_div {
        width: 50%;
        @extend .Column;
        justify-content: center;
        padding: 20px;

        .component4_image {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    .component4_content_div {
        width: 50%;
        @extend .Column;
        justify-content: space-around;
        padding: 20px;
        padding-inline: 32px;
        align-items: flex-start;

        .component4_button_div {
            @extend .Row;
            justify-content: flex-start;
            margin-block-start: 40px;
        }
    }
}

.sharktank_container {
    width: 100%;
    @extend .Row;
    padding-block: 40px;
    align-items: stretch;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
    min-height: 400px;
}

.STLogos_container {
    flex: 1;
    margin-inline: 16px;
    padding-inline: 16px;
    @extend .Column;
    justify-content: center;

    .STLogos_image {
        width: 100%;
        height: 150px;
        object-fit: contain;
    }
}

.pricing_selector_container {
    @extend .Row;
    justify-content: center;
    width: 100%;
    margin-block: 24px;

}

.pricing_button {
    @extend .Row;
    justify-content: center;
    // margin-inline: 16px;
    padding-block: 8px;
    padding-inline: 24px;
    // background-color: #fff;
    // border: 1px solid black;
    // border-radius: 12px;
    width: auto;
    cursor: pointer;
}

.pb_unselected {
    background-color: white;
    border: 1px solid black;
}

.pb_selected {
    background-color: #9357FF;
    border: 1px solid #9357FF;
}

.pb_left {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.pb_right {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.off_span {
    font-size: 10px;
    font-weight: 400;
    color: white;
    padding-block: 4px;
    padding-inline: 6px;
    border-radius: 6px;
    background-color: #FEAF52;
    margin-inline-start: 6px;
}

.feature_strip_container {
    width: 100%;
    margin-block: 24px;
    @extend .Row;
    align-items: stretch;
}

.feature_item_container {
    flex: 1;
    padding: 4px;
    // background-color: chartreuse;

    .feature_item {
        width: 100%;
        height: 100%;
        @extend .Column;
        justify-content: space-around;
        align-items: flex-start;
        padding-inline: 16px;
        padding-block: 16px;
        background-color: #FAFAFA;

        .fname {
            font-size: 14px;
            font-weight: 700;
            color: white;
        }
    }

}

.tab_container {
    margin-block: 24px;
    width: 100%;
    // background-color: #FEAF52;
    @extend .Row;
    align-items: stretch;

    .tab_left_container {
        width: 30%;
        height: auto;
        @extend .Column;
        justify-content: space-around;
        // background-color: blueviolet;
        padding-inline: 24px;
    }

    .tab_right_container {
        width: 70%;
        height: auto;
        @extend .Column;
        justify-content: space-around;
        // background-color: burlywood;
    }
}

.tab_item_container {
    @extend .Row;
    width: 100%;
    justify-content: flex-start;
    margin-block: 8px;
    padding-block: 12px;
    padding-inline: 16px;
    border: 1px solid #CCCCCC;
    border-radius: 12px;
    background-color: white;
    cursor: pointer;

    .ti_icon {
        height: 20px;
        width: 20px;
        object-fit: contain;
        margin-inline-end: 16px;
    }
}

.ti_selected {
    border-color: #9357FF;
    background-color: #9357FF;
}

.tab_slide_container {
    @extend .Column;
    justify-content: space-between;
    align-items: flex-start;
    padding-block: 32px;
    padding-inline: 40px;
    border: 1px solid #CCCCCC;
    border-radius: 12px;
    background-color: white;
    overflow: hidden;
    height: 440px;
    width: 100%;

    .ts_body_container {
        flex: 1;
        @extend .Column;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        margin-block: 24px;
        overflow-y: auto;
    }

    .ts_footer_container {
        @extend .Row;
        width: 100%;
        align-items: flex-end;

        &__text {
            @extend .Column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        &__icon {
            height: 60px;
            width: 150px;
            // width: auto;
            object-fit: contain;
        }
    }
}

.footer_top {
    width: 100%;
    margin-block: 30px;
    @extend .Row;
    align-items: stretch;
    justify-content: space-around;

    &__sub_container {
        width: fit-content;
        @extend .Column;
        justify-content: flex-start;
        align-items: flex-start;

        .ftheader {
            font-weight: 700;
            margin-block-end: 20px;
        }

        .fttext {
            font-weight: 400;
            margin-block: 6px;
            cursor: pointer;
        }
    }
}

.footer_bottom {
    padding-block-start: 30px;
    padding-block-end: 20px;
    border-top: 1px solid #CCCCCC;
    width: 100%;
    @extend .Row;
    justify-content: space-around;

    &__sub_container {
        @extend .Row;
        justify-content: flex-start;

        .fbtext {
            margin-inline-end: 24px;
            cursor: pointer;
        }

        .fbcopyright {
            height: 18px;
            width: 18px;
            object-fit: contain;
            margin-inline-end: 16px;
        }
    }
}