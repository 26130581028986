@use '../abstracts/colors' as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.agent_short_url_container {
    @extend .Column;
    justify-content: center;
    height: 100dvh;
    width: 100%;

    img { 
        height: f.rem(150);
        width: f.rem(150);
        object-fit: contain;
        margin-block: f.rem(16);

        @include b.breakpoint(large){
            height: f.rem(300);
            width: f.rem(300);
        }
    }

    p {
        font-size: f.rem(16);
        font-weight: 500;
        color: colors.$black01;
        margin-block: f.rem(16);
        width: 80%;
        text-align: center;

        @include b.breakpoint(large){
            font-size: f.rem(24);
            font-weight: 700;
            width: 40%;
        }
    }
}