.mailCsv {
  max-height: calc(100vh - 130px);
  width: 30vw;
  margin-top: 30%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
}
