body {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.verythinscrollnew::-webkit-scrollbar {
  width: 0.5em;
  background-color: #fafafa;
}
.verythinscrollnew::-webkit-scrollbar-track {
  background-color: #fafafa;
}
.verythinscrollnew::-webkit-scrollbar-thumb {
  /* background:  #666666; */
  background: #e6e6e6;
  border-radius: 100vw;
  border: 0.125em solid #f5f5f5;
}

.my-modal {
  max-width: 1012px;
  max-height: 671px;
  border-radius: 0px;
}
.btn-close {
  background-image: url('../../Images/contactsNew/CMClose.png');
  opacity: 1;
}

.editable {
  width: 360px;
  height: 120px;
  border: 1px solid #ccc;
}

.editable .span-0 {
  font-weight: normal;
  text-decoration: none;
  font-style: normal;
}

.editable .span-b {
  font-weight: bold;
}

.editable .span-u {
  text-decoration: underline;
}

.editable .span-i {
  font-style: italic;
}

::-moz-placeholder {
  font-size: 12px;
  opacity: 0.2;
  color: grey;
  
}

::-webkit-input-placeholder {
font-size: 12px;
opacity: 0.2;
color: grey;
}


@media screen and (max-width: 1200px) {
  .btncustom {
    width: 100%; 
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .btncustom {
    flex-direction: column;
    width: auto;
  }
}


.header-input-group {
  display: flex;
  align-items: center;
  width: 100%;
}

.header-label {
  flex: 0 0 auto;
  margin-right: 10px;
}

.media-select {
  flex-grow: 1;
}