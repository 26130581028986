@use '../abstracts/colors' as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.announcement_container {
    @extend .Column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    padding-block: f.rem(8);
    padding-inline: f.rem(16);
    overflow-y: auto;
    // background-color: aqua;

    @include b.breakpoint(large){
        padding-block: f.rem(20);
        padding-inline: f.rem(40);
    }

    &__scheduled_header_container {
        @extend .Row;
        width: 100%;

        h3 {
            margin-block: f.rem(8);
            color: colors.$black01;
        }
    }

    &__header_container {
        @extend .Row;
        width: 100%;
        // background-color: burlywood;

        @include b.breakpoint-down(small){
            flex-direction: column;
            align-items: flex-start;
        }

        h3 {
            margin-block: f.rem(16);
            color: colors.$black01;
        }

    }

    .scheduled_campaign_table_container {
        @extend .Column;
        width: 100%;
        flex: 1;
        overflow-x: auto;
        min-height: 30dvh;
        // height: 100%;
        align-items: flex-start;
        align-self: flex-start;
        margin-block: f.rem(8);
        border: 1px solid colors.$borderwhite;
        background-color: colors.$white01;

        @include b.breakpoint(large){
            padding-block: f.rem(16);
            margin-block-end: f.rem(24);
            padding-inline: f.rem(20);
            border-radius: f.rem(16);
        }
    }

    .campaign_table_container {
        @extend .Column;
        width: 100%;
        flex: 1;
        // height: fit-content;
        // height: 70dvh;
        min-height: 50dvh;
        overflow-x: auto;
        align-items: flex-start;
        align-self: flex-start;
        margin-block: f.rem(8);
        // background-color: brown;
        border: 1px solid colors.$borderwhite;

        @include b.breakpoint(large){
            padding-block: f.rem(16);
            padding-inline: f.rem(20);
            background-color: colors.$white01;
            border-radius: f.rem(16);
        }
    }
}