@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

h1,h2 {
    font-weight: 700;
    line-height: 1.1;
    margin: 0;
}
h3,h4 {
    font-weight: 500;
    line-height: 1;
    margin: 0;
}

h1 {
    font-size: f.rem(20);
    @include b.breakpoint(medium){
        font-size: f.rem(24);
    }
    @include b.breakpoint(large){
        font-size: f.rem(28);
    }
    // font-size: clamp( 18px , 14px + 1.6vw , 40px );
    // font-size: clamp( f.rem(18) , f.rem(14) + 1.6vw , f.rem(40) );
}
h2 {
    font-size: f.rem(18);
    @include b.breakpoint(medium){
        font-size: f.rem(21);
    }
    @include b.breakpoint(large){
        font-size: f.rem(24);
    }
    // 16, 12+1.5 + 36
    // font-size: clamp( f.rem(16) , f.rem(12) + 1.5vw , f.rem(34));
}
h3 {
    font-size: f.rem(16);
    @include b.breakpoint(medium){
        font-size: f.rem(18);
    }
    @include b.breakpoint(large){
        font-size: f.rem(20);
    }
    // 14, 10+1.4 + 32
    // font-size: clamp( f.rem(14) , f.rem(10) + 1.4vw , f.rem(28));
}
h4 {
    font-size: f.rem(14);
    @include b.breakpoint(medium){
        font-size: f.rem(15);
    }
    @include b.breakpoint(large){
        font-size: f.rem(16);
    }
    // 14, 10+1.4 + 32
    // font-size: clamp( f.rem(12) , f.rem(8) + 1.3vw , f.rem(22));
}

// 18,14+1.6 ,40
// 16,12+1.5 ,36
// 14,10+1.4 ,32
// 

p {
    margin-block: 0;
    padding: 0;
    font-weight: 400;
    // font-size: 16px;
    // font-size: 1rem;
    font-size: f.rem(12);
    @include b.breakpoint(medium){
        font-size: f.rem(14);
    }
    @include b.breakpoint(large){
        font-size: f.rem(16);
    }
    // font-size: clamp(f.rem(12), f.rem(10) + 1.2vw , f.rem(16));
}

a, a:visited, a:active {
    text-decoration: none;
}