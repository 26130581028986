@use '../../sass/abstracts/colors' as colors;
@use '../../sass//abstracts/functions' as f;
@use '../../sass/layout/breakpoints' as b;

.KeywordsDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 27px 37px 37px 37px;

    .note {
        color: #666666;
        font-weight: 600;
        font-size: 16px;
        white-space: nowrap;
        /* Prevent text from wrapping */
        overflow: hidden;
        /* Hide overflowed text */
        text-overflow: ellipsis;
    }

    .flex {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .search_container {
        display: flex;
        width: 100%;
        align-items: flex-start;

        @include b.breakpoint(medium) {
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }

        .search_upload_row {
            // background-color: blueviolet;
            margin-inline-end: 0;


            @include b.breakpoint-down(small) {
                width: 100%;
            }

            .logged_input {
                font-size: f.rem(14);
                // color: brown;
                border: 1px solid colors.$black04;
                border-radius: f.rem(12);
                padding-block: f.rem(7);
                padding-inline: f.rem(16);
                // background-color: chartreuse;

                ::placeholder {
                    color: colors.$black04;
                    opacity: 1;
                }

                ::-ms-input-placeholder {
                    /* Edge 12-18 */
                    color: colors.$black04;
                }

                .search_input {
                    margin-inline-start: f.rem(6);
                    appearance: none;
                    border: none;
                    background: none;
                    padding: 0;
                    outline: none;
                    font-size: f.rem(14);
                    color: colors.$black01;
                    opacity: 1;
                }
            }
        }
    }

    .table {
        width: 100%;
        flex: 1;
        margin-top: 50px;
        overflow: hidden;
    }

    .keywordData {
        display: flex;
        gap: 10px;
    }

    .keyword {
        background-color: #F5F5F5;
        flex-wrap: wrap;
        border-radius: 8px;
        padding: 5px 10px;
    }

    .replyAction {
        color: #FEAF52;
        background-color: rgba(255, 196, 44, 0.19);
        border-radius: 8px;
        border: 1px solid #FEAF52;
        flex-wrap: wrap;
        padding: 5px 10px;
    }

    .buttons {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        padding: 22px 0;
    }
}