.animcustom {
  display: block;
  margin: 0 auto;
}
.animcustom[wobble='1'] {
  animation: wobble 1s 1;
}

@keyframes wobble {
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
