@use '../abstracts/colors' as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.image24 {
    height: f.rem(24);
    width: f.rem(24);
    object-fit: contain;
    cursor: pointer;
}

.kwonhover:hover {
    background-color: colors.$primary00;
}

.crhover {
    border-color: colors.$borderwhite;
    // background-color: aqua;
}
.crhover:hover {
    border-color: colors.$primary03;
    // background-color: aqua;
}

.keywords_sidebar_item {
    @extend .Row;
    justify-content: flex-start;
    margin-block-start: f.rem(20);
    cursor: pointer;
    padding-inline: f.rem(12);
    padding-block: f.rem(8);
    border-radius: f.rem(8);

    .check_image {
        height: f.rem(20);
        width: f.rem(20);
        object-fit: contain;
        margin-inline-end: f.rem(12);
    }

    p {
        color: colors.$black01;
    }
}

.keywords_action_container {
    height: 100%;
    width: 100%;
    @extend .Row;

    &__sidebar {
        width: f.rem(222);
        height: 100%;
        padding-block: f.rem(24);
        padding-inline: f.rem(20);
        background-color: colors.$white01;

        .header_row {
            @extend .Row;
            justify-content: flex-start;
            margin-block-end: f.rem(32);

            h3 {
                color: colors.$black01;
                font-weight: 700;
                margin-inline-start: f.rem(8);
            }
        }
    }

    &__content {
        height: 100%;
        width: calc(100% - f.rem(222));
        padding-block: f.rem(32);
        padding-inline: f.rem(24);

        &__content_container {
            height: 100%;
            width: 100%;
            border-radius: f.rem(10);
            background-color: colors.$white01;
        }
    }
}

.addkeywords_container {
    @extend .Column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding-block: f.rem(20);
    padding-inline: f.rem(24);

    &__header_row {
        @extend .Row;
        justify-content: flex-start;
        width: 100%;

        h4 {
            font-weight: 600;
            color: colors.$black01;
            margin-inline-end: f.rem(8);
        }
    }

    &__match_row {
        @extend .Row;
        justify-content: flex-start;
        width: 100%;
        margin-block: f.rem(24);
    }
}

.replyactions_container {
    @extend .Row;
    width: 100%;
    height: 100%;

    &__sidebar {
        @extend .Column;
        justify-content: flex-start;
        width: f.rem(216);
        height: 100%;
        padding-block: f.rem(24);
        padding-inline: f.rem(16);
        border-right: 1px solid colors.$borderwhite;
    }

    &__content {
        @extend .Column;
        height: 100%;
        width: calc(100% - f.rem(216));
        padding-block: f.rem(24);
        padding-inline: f.rem(20);
    }
}

.ra_sidebar_item {
    @extend .Row;
    justify-content: flex-start;
    width: 100%;
    margin-block-end: f.rem(12);
    padding-block: f.rem(8);
    padding-inline: f.rem(10);
    border-radius: f.rem(8);
    cursor: pointer;

    h4 {
        font-weight: 600;
        // color: colors.$black01;
        margin-inline-start: f.rem(8);
    }
}

.rahover:hover {
    background-color: colors.$primary00;
}

.saved_responses_container {
    @extend .Column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;

    &__header_row {
        @extend .Row;
        width: 100%;

        h3 {
            color: colors.$black01;
            font-weight: 600;
        }
    }

    &__response {
        @extend .Column;
        justify-content: flex-start;
        width: 100%;
    }
}

.media_responses_container {
    @extend .Column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;

    &__header_row {
        @extend .Row;
        width: 100%;

        h3 {
            color: colors.$black01;
            font-weight: 600;
        }
    }

    &__upload_box {
        @extend .Column;
        justify-content: flex-start;
        width: 100%;
        margin-block: f.rem(24);
    }
}

.workflow_responses_container {
    @extend .Column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;

    &__header_row {
        @extend .Row;
        width: 100%;

        h3 {
            color: colors.$black01;
            font-weight: 600;
        }
    }
}

.agent_responses_container {
    @extend .Column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;

    &__header_row {
        @extend .Row;
        width: 100%;

        h3 {
            color: colors.$black01;
            font-weight: 600;
        }
    }
}

.template_responses_container {
    @extend .Column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;

    &__header_row {
        @extend .Row;
        width: 100%;

        h3 {
            color: colors.$black01;
            font-weight: 600;
        }
    }
}