@use '../abstracts/colors' as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.agent_dashboard_container {
    @extend .Column;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    background-color: colors.$white02;
    padding-block: f.rem(30);
    padding-inline: f.rem(30);

    &__header_row {
        @extend .Row;
        width: 100%;
        // background-color: bisque;

        h3 {
            font-weight: 700;
        }

        &__right {
            @extend .Row;
            // background-color: blueviolet;
        }
    }

    &__tickets_row {
        @extend .Row;
        width: 100%;
        margin-block: f.rem(30);
        align-items: stretch;
        // background-color: chartreuse;
    }

    &__response_row {
        @extend .Row;
        width: 100%;
        flex: 1;
        max-height: f.rem(500);
        // background-color: cadetblue;
    }
}

.shadow_small {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
}

.ticket_component_container {
    @extend .Column;
    width: 23%;
    padding-block: f.rem(16);
    padding-inline: f.rem(24);
    border-radius: f.rem(12);
    border: 1px solid colors.$borderwhite;
    background-color: colors.$white01;

    .count_style {
        width: 100%;
        font-size: f.rem(36);
        font-weight: 700;
        color: colors.$black01;
        // background-color: aqua;
    }

    .label_style {
        width: 100%;
        font-size: 16;
        font-weight: 400;
        color: colors.$greys01;
        // background-color: brown;
    }
}

.response_component_container {
    width: 40%;
    height: 100%;
    // background-color: cornflowerblue;
    padding-inline-end: f.rem(10);

    .response_component_div {
        height: 100%;
        width: 100%;
        background-color: colors.$white01;
        border: 1px solid colors.$borderwhite;
        border-radius: f.rem(12);
        @extend .Column;
        justify-content: space-between;
        padding-block: f.rem(16);
        padding-inline: f.rem(24);

        h3 {
            font-weight: 700;
            width: 100%;
        }

        &__row {
            @extend .Row;
            width: 100%;
            align-items: stretch;
            // background-color: crimson;

            .response_item {
                @extend .Column;
                width: 48%;
                align-items: flex-start;

                &__count {
                    font-size: f.rem(24);
                    font-weight: 700;
                    color: colors.$black01;
                    margin-block-start: f.rem(24);
                }

                &__label {
                    font-size: f.rem(16);
                    font-weight: 400;
                    color: colors.$greys01;
                    margin-block: f.rem(12);
                }
            }
        }
    }
}

.graph_component_container {
    width: 60%;
    height: 100%;
    padding-inline-start: f.rem(10);

    .graph_component_div {
        height: 100%;
        width: 100%;
        background-color: colors.$white01;
        border: 1px solid colors.$borderwhite;
        border-radius: f.rem(12);
        @extend .Column;
        justify-content: space-between;
        padding-block: f.rem(16);
        padding-inline: f.rem(24);

        &__row {
            @extend .Row;
            width: 100%;
            margin-block-end: f.rem(16);

            h3 {
                font-weight: 700;
            }
        }

        &__graph {
            width: 100%;
            flex: 1;
            // background-color: chocolate;
            @extend .Column;
            justify-content: center;
        }
    }
}