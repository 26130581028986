/* .scrollbary{
    -ms-overflow-style: none;  
    scrollbar-width: 0;  
    background-color: aqua;
} */

.btncustom:focus {
  outline: none !important;
  border-color: #a470ff;
  border-width: 1;
  box-shadow: 0 0 4px #a470ff;
}

::-webkit-scrollbar {
  width: 1em;
}
::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 100vw;
  margin-block: 0.5em;
}
::-webkit-scrollbar-thumb {
  background: #e3e3e3;
  border-radius: 100vw;
  border: 0.25em solid #fff;
}
