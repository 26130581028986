.my-modal {
  width: '120%';
}
.automatic-modal-after-model .modal-content{
  border-radius: 16px;
  width: 570px;
  height: 695px;
  right: 11%;
  overflow: hidden;
}

.automatic-modal .modal-content {
  border-radius: 16px;
  width: 1050px;
  height: 708px;
  right: 50%;
  overflow: hidden;
  margin-top: -10px;
}
.automatic-modal {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0px;
  /* width: 100%; */
  height: 100%;
  z-index: var(--bs-modal-zindex);
}
.banner-model .modal-content {
  border-radius: 16px;
  width: 840px;
  height: 500px;
  overflow: hidden;
  right: 30%;
  margin-top: 22%;
  background-color: white;
  position: relative;
}
.automatic-modal-body .modal {
  --bs-modal-zindex: 1055;
  /* --bs-modal-width: 900px; Remove or comment this line */
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  /* width: 100%;
  height: 100%; */
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}


.custom-modal-dialog {
  max-width: 120%;
  /* Adjust the width as needed */
  margin: 1rem auto;
  /* Center the modal horizontally */
}

.modal-height {
  width: 120%;
  border: '2px solid red';
  height: auto;
  overflow: auto;
}

.paginate {
  overflow: hidden;
  align-self: flex-end;
  width: 100%;
  border: 1px solid #B3B3B3;
  border-bottom: none;
  height: 50px;
}


.MuiTablePagination-input {
  margin: 0px;
  height: 10px;

}

.MuiTablePagination-selectLabel {
  margin-bottom: 5px;

}

.MuiTablePagination-displayedRows {
  margin: 0px;

}

.MuiTablePagination-actions {
  margin-left: -20px;
}

.MuiAutocomplete-input {
  border: 2px solid red;
  height: 30px;
}

.loggedInput {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--border-color-border-medium, #CCC);
  padding: 7px 8px;
  font-size: 14px;
  gap: 8px;


}

.input {
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  outline: none;
}

.submitTemplateScroll::-webkit-scrollbar {
  width: 0.5em;
  /* margin-block: 12px; */
  background-color: #fff;
}

.submitTemplateScroll::-webkit-scrollbar-track {
  /* margin: 12px; */
  background-color: #fff;
}

.submitTemplateScroll::-webkit-scrollbar-thumb {
  /* background:  #666666; */
  background: #e6e6e6;
  border-radius: 100vw;
  border: 0.125em solid #f5f5f5;
}

.WAPreviewScroll::-webkit-scrollbar {
  width: 0.5em;
  /* margin-block: 12px; */
  background-color: #fff;
}

.WAPreviewScroll::-webkit-scrollbar-track {
  /* margin: 12px; */
  background-color: #fff;
}

.WAPreviewScroll::-webkit-scrollbar-thumb {
  /* background:  #666666; */
  /* background:  #E6E6E6; */
  background: #fff;
  border-radius: 100vw;
  border: 0.125em solid #fff;
}

.custom-modal .modal-dialog {
  max-width: 50%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-modal .modal-content {
  width: 100%;
  max-height: 90vh; 
  overflow-y: auto;
}

.wallet-topup-modal {
  margin-top: 5vh;
}

.wallet-topup-modal .modal-dialog {
  max-width: 600px; 
  margin: 20px auto;
}