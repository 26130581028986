@use '../abstracts/colors' as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sms_dlt_modal_container {
  @extend .Column;
  justify-content: flex-start;
  max-height: 80vh;
  // min-height: 70vh;
  height: auto;
  width: min(90vw, f.rem(500));
  background-color: colors.$white01;
  border-radius: f.rem(16);
  z-index: 0;
  border: 1px solid colors.$borderwhite;
  padding-inline: f.rem(24);
  padding-block: f.rem(20);
  // overflow-y: auto;

  &__header {
    @extend .Row;
    width: 100%;

    h3 {
      color: colors.$black01;
    }
  }

  p {
    color: colors.$greys01;
    width: 100%;
    margin-block: f.rem(16);
  }
}
