/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  left: 100%;
  top: 0;
  position: absolute;
  background-color: #f9f9f9;
  margin-top: 0;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  width: 110%;
}

/* Links inside the dropdown */
.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content div:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.responses {
  border: 1px solid #cccccc;
  background-color: whitesmoke;
  bottom: 45px;
  left: 32%;
  position: absolute;
  background-color: #f9f9f9;
  margin-top: 0;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  height: 50%;
  width: 25%;
}

.response-content div:hover {
  background-color: #f1f1f1;
  border-radius: 16px;
}

.responses-matched {
  border: 1px solid #cccccc;
  background-color: white;
  bottom: 45px;
  right: 31%;
  position: absolute;
  margin-top: 0;
  min-width: 160px;
  z-index: 1;
  border-radius: 8px;
  height: 50%;
  width: 25%;
}

.mainInput:focus {
  border: 0 none #fff;
  outline: none;
  box-shadow: none;
}

.mainInput::placeholder {
  font-size: 14px;
  align-self: flex-end;
  flex-direction: column;
}

.inputAgent::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #666666;
  border: 'none';
  opacity: 1;
}

.inputAgent::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #666666;
  border: none;
  opacity: 1;
}

.filterDropdown {
  position: absolute;
  top: 70px;
  max-height: 80vh;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 15;
  width: 21%;
  margin-left: 70px;
}

.repliesSidebar {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  background: var(--whites-white-01, #fff);
  box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.25);
  width: min(100%, 483px);
  z-index: 1;
  overflow-y: auto;
  scrollbar-width: thin;
}

.resolveDiv:hover {}

.comment-div {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--border-color-border-medium, #ccc);
  padding: 7px 8px;
  font-size: 14px;
  gap: 8px;
  width: 95% !important;
}

.comment-field {
  width: 95% !important;
  height: 40px !important;
  outline: none !important;
  overflow-y: auto;
  resize: none;
  scrollbar-width: thin;
  appearance: none;
  border: none;
  background: none;
  outline: none;
}

.comment-field:focus {
  height: 8rem !important;
}

.comment-field::placeholder {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  opacity: 1;
  color: #cccccc;
  align-self: flex-end;
  margin: 10px !important;
}

.conversation:hover {
  border: 1px solid black;
}

.emojiMenu {
  position: absolute;
  bottom: 60px;
  max-height: 80vh;
  background-color: white;
  z-index: 2;
  width: 20vw;

  margin-left: 50px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  padding: 16px;
  width: 300px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1010;
}

.modal-header {
  font-weight: bold;
  margin-bottom: 8px;
}

.modal-select {
  width: 100%;
  margin-top: 8px;
}

.modal-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  gap: 10px;
}

.modal-button {
  width: 45%;
  height: 36px;
  border-radius: 10px;
  padding-inline: 8px;
  font-size: 14px;
  font-weight: 600;
}