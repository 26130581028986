.btncustom:focus {
  outline: none !important;
  border-color: #a470ff;
  border-width: 1;
  box-shadow: 0 0 4px #a470ff;
}
.btncustom:focus:active {
  box-shadow: 0 0 4px #a470ff;
}

select {
  max-height: 200px;
}

option {
  /* background-color: aqua; */
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
  color: black;
}

.customScroll::-webkit-scrollbar {
  width: 1em;
}
.customScroll::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 100vw;
  margin-block: 0.5em;
}
.customScroll::-webkit-scrollbar-thumb {
  /* background:  #e3e3e3; */
  background: #666666;
  border-radius: 100vw;
  border: 0.25em solid #f5f5f5;
}
