@use '../abstracts/colors' as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.contacts_container {
  @extend .Column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  // background-color: aquamarine;
  overflow: hidden;
  // overflow-y: auto;

    @include b.breakpoint(large){
        padding-inline: f.rem(40);
        padding-block: f.rem(20);
    }

  .common_div {
    @extend .Column;
    width: 100%;
    align-items: flex-start;

    @include b.breakpoint(medium) {
      flex-direction: row;
      align-items: center;
      // background-color: aqua;
    }

        .page_dropdownselect_container {
            @extend .Row;
            margin-block: f.rem(8);
          
            .page_dropdownselect {
              box-sizing: content-box;  
              appearance: none;
              cursor: pointer;
              background-color: transparent;
            //   background-color:brown;
              border: none;
              padding-block: f.rem(6);
              line-height: normal;
            //   margin: 10;
              font-size: f.rem(20);
              font-weight: 500;
            //   height: fit-content;
            //   width: max-content;
              text-align: start;
              text-transform: capitalize;
              outline: none;

              option {
                font-size: f.rem(16);
              }
            }
            .page_dropdownselect_2 {
              // background-color:brown;
              width: max-content;
              padding-block: f.rem(6);
              line-height: normal;
            //   margin: 10;
              font-size: f.rem(20);
              font-weight: 500;
            //   height: fit-content;
            //   width: max-content;
              text-align: start;
              // text-transform: capitalize;
            }
        }

        .search_container {
          @extend .Column;
          width: 100%;
          align-items: flex-start;

          @include b.breakpoint(medium){
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
          }

          .search_upload_row {
            @extend .Row;
            // background-color: blueviolet;
            margin-inline-end: 0;
            

            @include b.breakpoint-down(small){
                width: 100%;
            }

            .logged_input {
                font-size: f.rem(14);
                // color: brown;
                border: 1px solid colors.$black04;
                border-radius: f.rem(12);
                padding-block: f.rem(10);
                padding-inline: f.rem(16);
                // background-color: chartreuse;
                @extend .Row;

              ::placeholder {
                color: colors.$black04;
                opacity: 1;
              }

              ::-ms-input-placeholder { /* Edge 12-18 */
                color: colors.$black04;
              }

              .search_input {
                margin-inline-start: f.rem(6);
                appearance: none;
                border: none;
                background: none;
                padding: 0;
                outline: none;
                font-size: f.rem(14);
                color: colors.$black01;
                opacity: 1;
              }
            }
          }
        }        
  }

  
  .table_div {
    flex: 1;
    width: 100%;
    // background-color: burlywood;
    margin-block: f.rem(10);
    overflow-x: scroll;
    // overflow-y: scroll;

    @include b.breakpoint(large) {
      overflow-x: hidden;
    }

    .scrollable_table {
      width: max(f.rem(900), 100%);
      // width: fit-content;
      height: 100%;
      // overflow-y: auto;
      // overflow-x: auto;
      display: flex;
      flex-direction: column;
      // align-items: flex-start;
      // background-color: chartreuse;
    }
  }

}


