@use "../abstracts/colors" as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.announcement_modal {
    @extend .Column;
    // height: 80vh;
    height: 100dvh;
    // width: min(90vw, f.rem(1012));
    width: 100vw;
    // background-color:aqua;
    background-color: colors.$white01;
    // border-radius: f.rem(16);
    z-index: 0;
    border: 1px solid colors.$borderwhite;
    padding-inline: f.rem(20);
    // padding-block: f.rem(8);

    @include b.breakpoint(large){
        height: 80vh;
        width: min(90vw, f.rem(1012));
        padding-inline: f.rem(24);
        border-radius: f.rem(16);
        // padding-block: f.rem(20);
    }
}

.announcement_modal_header {
    @extend .Row;
    width: 100%;
    // background-color: aquamarine;
    padding-block: f.rem(16);
    border-bottom: 1px solid colors.$borderwhite;

    &__left_container {
        @extend .Row;
    
        h2 {
            color: colors.$black01;
            margin-inline: f.rem(2);
        }
    }

    &__right_container {
        @extend .Row;


    }

    img {
        height: f.rem(24);
        width: f.rem(24);
        object-fit: contain;
        cursor: pointer;
        margin-inline: f.rem(2);

        // @include b.breakpoint(large){
        //     height: f.rem(32);
        //     width: f.rem(32);
        // }
    }
}

.ann_templates_select {
    @extend .Row;
    width: 100%;
    margin-block: f.rem(2);
    border-bottom: 1px solid colors.$borderwhite;

    @include b.breakpoint(large){
        margin-block: f.rem(8);
        justify-content: flex-start;
    }
}

.ann_templates_select_item {
    @extend .Row;
    height: 100%;
    cursor: pointer;

    @include b.breakpoint-down(medium){
        width: 50%;
    }

    p {
        padding-block: f.rem(4);
        padding-inline: f.rem(24);
        width: 100%;
        text-align: center;

        @include b.breakpoint(large){
            padding-block: f.rem(8);
            padding-inline: f.rem(16);
        } 
    }
}

.ann_details_container {
    @extend .Column;
    justify-content: flex-start;
    width: 100%;
    height: calc( 100% - 80px);
    // background-color: brown;
    // overflow-y: auto;

    @include b.breakpoint-down(medium){
        overflow-y: auto;
    }

    @include b.breakpoint(large){
        flex-direction: row;
        justify-content: space-between;
    }

    &__form {
        @extend .Column;
        justify-content: flex-start;
        width: 100%;
        // height: 100%;
        padding-inline: f.rem(16);

        @include b.breakpoint(large){
            width: 60%;
            height: 100%;
            overflow-y: scroll;
        }
    }

    &__preview {
        @extend .Column;
        justify-content: flex-start;
        width: 100%;
        // height: 100%;
        // background: aqua;
        padding-inline: f.rem(16);
        padding-block: f.rem(16);

        @include b.breakpoint(large){
            width: 40%;
            height: 100%;
            // white-space: nowrap;
            // flex: 1;
            overflow-y: scroll;
            padding-block-start: f.rem(30);
        }
    }
}

.ann_templates_v1_container {
    @extend .Column;
    justify-content: flex-start;
    width: 100%;
    flex: 1;
    overflow-y: auto;
    // background-color: cadetblue;
    margin-block: f.rem(8);

    .row_wrapper {
        @extend .Row;
        justify-content: flex-start;
        width: 100%;
        flex-wrap: wrap;
        padding-inline: f.rem(8);
        margin-block: f.rem(8);
        // background-color:chartreuse;
    }
}
.ann_template_v1_item_container {

    width: 50%;
    height:max-content;
    // height: auto;
    padding-inline: f.rem(8);
    padding-block: f.rem(8);

    @include b.breakpoint-down(medium){
        width: 100%;
    }

    .ann_template_v1_item {
        @extend .Column;
        // height: f.rem(200);
        width: 100%;
        height: f.rem(220);
        // height: f.rem;
        // width: calc( 45% - f.rem(32));
        background-color: colors.$white02;
        border: 1px solid colors.$borderwhite;
        padding-block: f.rem(14);
        padding-inline: f.rem(20);
        border-radius: f.rem(16);
        // margin-inline: f.rem(8);

        @include b.breakpoint-down(medium){
            // height: f.rem(220);
            height: fit-content;
        }

        &__row1 {
            @extend .Row;
            width: 100%;
            flex: 1;
            overflow-y: hidden;

            img {
                height: f.rem(100);
                width: f.rem(100);
                object-fit: contain;

                @include b.breakpoint-down(medium){
                    height: f.rem(50);
                    width: f.rem(50);
                }
            }

            .text_container {
                @extend .Column;
                height: 100%;
                justify-content: flex-start;
                align-items: flex-start;
                flex: 1;
                padding-inline-start: f.rem(16);
                overflow-y:hidden;
                text-overflow: ellipsis;

                p {
                    color: colors.$black01;
                }

                .header_text {
                    font-weight: 700;
                }

            }
        }

        &__row2 {
            @extend .Row;
            width: 100%;
            margin-block-start: f.rem(16);

            @include b.breakpoint-down(medium){
                flex-direction: column;

            }
        }
    }
}

.ann_template_v2_item_container {
    width: 50%;
    padding: f.rem(10);

    @include b.breakpoint-down(medium){
        width: 100%;
    }

    .ann_template_v2_item {
        @extend .Column;
        width: 100%;
        height: f.rem(220);
        border: 1px solid colors.$borderwhite;
        border-radius: f.rem(16);
        padding-inline: f.rem(20);
        padding-block: f.rem(16);
        background-color: colors.$white02;

        @include b.breakpoint-down(medium){
            // height: f.rem(220);
            height: fit-content(f.rem(220));
            // height: fit-content;
        }

        &__row1 {
            @extend .Row;
            width: 100%;
            flex: 1;
            overflow-y: hidden;

            img {
                height: f.rem(100);
                width: f.rem(100);
                object-fit: contain;

                @include b.breakpoint-down(medium){
                    height: f.rem(50);
                    width: f.rem(50);
                }
            }

            .text_container {
                @extend .Column;
                height: 100%;
                justify-content: flex-start;
                align-items: flex-start;
                flex: 1;
                padding-inline-start: f.rem(16);
                overflow-y:hidden;
                text-overflow: ellipsis;

                p {
                    color: colors.$black01;
                }

                .header_text {
                    font-weight: 700;
                }
            }
        }

        &__row2 {
            @extend .Row;
            width: 100%;
            margin-block-start: f.rem(16);

            @include b.breakpoint-down(medium){
                flex-direction: column;

            }
        }
    }
}

.mobile_disabled {
    @include b.breakpoint-down(medium){
        display: none;
    }
}