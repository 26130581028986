.Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.SidemenuItem {
  height: 37px;
  border-radius: 8px;
  margin-block-end: 16px;
  cursor: pointer;
  width: 100%;
}

.SidemenuItem_Text {
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  margin-inline: 8px;
}

.Image {
  height: 24px;
  width: 24px;
  object-fit: contain;
}

.SidemenuContainer {
  height: 100%;
  transition: 'width 0.3s ease';
  padding-block: 20px;
}

.SidemenuInnerContainer {
  justify-content: flex-start;
  width: 100%;
}

.Sidemenu_ContactUs {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.HeaderContainer {
  width: 100%;
  height: 50px;
}

.HeaderSidemenu {
  width: 240px;
  padding-inline: 16px;
  height: 100%;
  justify-content: flex-start;
}

.HeaderSidemenuIcon {
  cursor: pointer;
  margin-inline-end: 8px;
}

.HeaderHeadingText {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}

.HeaderBodyText {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  margin-inline: 8px;
}

.HeaderRightComponent {
  justify-content: space-evenly;
  margin-inline: 16px;
}

.HeaderProfileImage {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  margin-inline: 8px;
}

.BannerContainer {
  width: 100%;
  padding-block: 20px;
  padding-inline: 24px;
  border-radius: 16px;
  margin-block: 16px;
}

.BannerText {
  padding: 0;
  margin: 0;
  margin-inline: 16px;
  font-size: 16px;
  font-weight: 600;
}

.BannerCheckText {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.ProfilePopupContainer {
  justify-content: center;
  position: absolute;
  top: 60px;
  right: 20px;
  width: 360px;
  border-radius: 16px;
  padding: 12px;
  z-index: 13;
}

.ProfilePopupInnerContainerTop {
  justify-content: center;
  width: 100%;
  border-radius: 16px 16px 0 0;
  padding-block: 14px;
  padding-inline: 20px;
}

.ProfilePopupImage {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-inline-end: 16px;
  object-fit: cover;
}

.ProfilePopupHeadText {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
}

.ProfilePopupBodyText {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
}

.ProfilePopupInnerContainerBottom {
  justify-content: center;
  width: 100%;
  border-radius: 0 0 16px 16px;
  padding-block: 14px;
  padding-inline: 20px;
  cursor: pointer;
}

.ProfilePopupLogoutText {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.BodyContainer {
  height: calc(100% - 50px);
  width: 100%;
}

.BodyInnerContainer {
  justify-content: flex-start;
  height: 100%;
  flex: 1;
  /* padding-inline: 40px; */
  /* padding-block: 20px; */
}

.BodyHeader {
  margin-block-end: 8px;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  text-align: start;
  padding-inline: 16px;
}

.BodyScrollable {
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: scroll;
}

.BodyCol70 {
  justify-content: flex-start;
  height: 100%;
  width: 70%;
  padding-inline: 16px;
}

.ProgressContainer {
  width: 100%;
  border-radius: 16px;
  margin-block: 16px;
}

.ProgressContainerLeft {
  height: 100%;
  align-items: flex-start;
  width: 60%;
  padding-block: 16px;
  padding-inline-start: 20px;
  margin-inline-end: 10px;
}

.ProgressHeaderText {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  margin-block: 4px;
  width: 100%;
}

.ProgressIcon {
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin-inline-end: 8px;
}

.ProgressBodyText {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
}

.ProgressContainerRight {
  height: 100%;
  align-items: flex-start;
  width: 40%;
  padding-block: 16px;
  padding-inline-start: 10px;
  margin-inline-end: 20px;
}

.ProgressRightText {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  width: 40%;
}

.ContactsContainer {
  height: 100%;
  width: 50%;
  border-radius: 16px;
  margin-inline-end: 16px;
  padding-block: 10px;
  padding-inline: 20px;
}

.TileIcon {
  height: 54px;
  width: 54px;
  object-fit: contain;
  margin-inline-end: 16px;
  border-radius: 12px;
}

.TileText {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  flex-wrap: wrap;
  word-break: break-word;
}

.DividerLine {
  height: 1px;
  width: calc(100% + 40px);
}

.CampaignContainer {
  height: 100%;
  width: 50%;
  border-radius: 16px;
  margin-inline-start: 16px;
  padding-block: 10px;
  padding-inline: 20px;
}

.BodyCol30 {
  justify-content: flex-start;
  height: 100%;
  width: 30%;
  padding-inline: 16px;
}

@media screen and (max-width: 1200px) {
  .btncustom {
    width: 100%; 
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .btncustom {
    flex-direction: column;
    width: auto;
  }
}

