.tickcustom:hover {
  background-color: white;
}
.tickcustom:focus {
  background-color: white;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

button.kep-login-facebook.metro {
  width: 100%;
  font-size: 0.8rem;
  border-radius: 8px;
  padding: 0.6rem 4rem;
  background: #4145cf;
  text-transform: capitalize;
  margin-top: 4rem;
}

.deleteTag {
  overflow: hidden;
  width: 100vw;
  position: absolute;
  left: 35%;
  top: 35%;
  border-radius: 16px;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
.badge-wrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}
.labels-badge-info {
  border: 1px solid #0dcaf0;
  font-size: 12px;
  border-radius: 5px;
  padding: 6px;
  text-transform: uppercase;
  margin: 5px 5px 0 0
}

.btn-save-contact {
  background-image: linear-gradient(
    to right,
    #4cb8c4 0%,
    #3cd3ad 51%,
    #4cb8c4 100%
  );
  /* /* margin: 10px; */
  padding: 8px 32px;
  text-align: center;
  /* text-transform: uppercase; */
  font-size: 12px;
  font-weight: 600;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-save-contact:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
