@use "../abstracts/colors" as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.form_url_page {
    @extend .Column;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100dvh;
    background-color: colors.$white02;
    overflow-y: auto;

    @include b.breakpoint(large){
        padding-block: f.rem(60)    
    }
}

.form_url_container {
    @extend .Column;
    width: 100%;
    // max-height: 100%;
    padding-block: f.rem(16);
    padding-inline: f.rem(20);
    // overflow-y: auto;

    @include b.breakpoint(large){
        width: 50%;
        // max-height: 80%;
        // border: 1px solid colors.$borderwhite;
        padding-block: f.rem(24);
        padding-inline: f.rem(40);
        border-radius: f.rem(12);
    }

    &__logo_container {
        @extend .Row;
        width: 100%;
    }

    &__headerImage_container {
        @extend .Row;
        width: 100%;
    }

    &__formTitle_container {
        @extend .Row;
        width: 100%;
    }

    &__formTitle_container {
        @extend .Row;
        width: 100%;
    }

    &__formButton_container {
        @extend .Row;
        width: 100%;
    }
    
    
}