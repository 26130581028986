$white01: #FFFF;
$white02: #FAFAFA;
$white03: #F5F5F5;
$white04: #F0F0F0;

$black01: #000;
$black02: #0D0D0D;
$black03: #404040;
$black04: #808080;

$primary00: #F5F0FF;
$primary01: #D9C3FF;
$primary02: #A470FF;
$primary03: #9357FF;
$primary04: #8E53F7;

$accent01: #FFEB88;
$accent02: #FFC945;
$accent03: #FFC433;
$accent04: #FFBA11;

$success01: #49B147;
$success02: #27B123;
$success03: #04B100;
$success04: #039600;

$failure01: #FF3333;
$failure02: #FF1A1A;
$failure03: #FF0000;
$failure04: #E55000;

$warning01: #FFCB66;
$warning02: #FFBA33;
$warning03: #FFA800;
$warning04: #E59700;

$greys01: #666666;
$greys02: #808080;
$greys03: #999999;
$greys04: #B3B3B3;

$link01: #3366CC;
$link02: #0043A8;
$link03: #300F61;

$borderwhite: #E6E6E6;
