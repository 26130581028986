@use "../abstracts/colors" as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.qr_modal_container {
    @extend .Column;
    justify-content: flex-start;
    height: 80vh;
    width: min(90vw, f.rem(500));
    background-color: colors.$white01;
    border-radius: f.rem(16);
    z-index: 0;
    border: 1px solid colors.$borderwhite;
    padding-inline: f.rem(16);
    padding-block: f.rem(10);

    @include b.breakpoint-down(medium){
        overflow-y: auto;
    }

    @include b.breakpoint(large){
        height: 70vh;
        padding-inline: f.rem(30);
        padding-block: f.rem(20);
    }

    &__header {
        @extend .Row;
        width: 100%;
        padding-block-end: f.rem(10);
        border-bottom: 1px solid colors.$borderwhite;

        @include b.breakpoint(large){
            padding-block-end: f.rem(16);
        }

        h2{
            color: colors.$black01;
        }

        img {
            height: f.rem(24);
            width: f.rem(24);
            object-fit: contain;
            cursor: pointer;
        }
    }

    &__result {
        @extend .Column;
        width: 90%;
        margin-block: f.rem(16);

        h2 {
            color: colors.$black01;
            width: 100%;
        }

        .results_row {
            @extend .Row;
            justify-content: flex-start;
            width: 100%;
            margin-block: f.rem(16);
        }
    }
}

.qr_result_item_container {
    display: block;
    justify-content: space-evenly;
    align-items: center;
    padding-block: f.rem(16);
    padding-inline: f.rem(32);
    border-radius: f.rem(10);
    border-width: f.rem(1);
    border: 1px solid colors.$borderwhite;

    .value_text {
        margin-block-end: f.rem(8);
        font-size: f.rem(16);
        font-weight: 700;
        color: colors.$primary03;
        text-align: center;

        @include b.breakpoint(large){
            font-size: f.rem(24);
        }
    }

    .title_text {
        margin-block-start: f.rem(8);
        font-size: f.rem(14);
        font-weight: 500;
        color: colors.$black01;

        @include b.breakpoint(large){
            font-size: f.rem(16);
        }
    }
}