.draggable-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: move;
    margin-top: 15px;
    background-color: white;
    transition: transform 0.2s ease, opacity 0.2s ease;
}

.draggable-item.default,
.draggable-item.hidden {
    cursor: not-allowed;
}

.draggable-item.dragging {
    transform: scale(1.03);
    opacity: 1;
    z-index: 1000;
}

.draggable-item.not-dragging {
    transform: scale(1);
    opacity: 0.6;
}

.drag-handle {
    background-color: transparent;
    border: none;
    color: black;
    cursor: move;
    display: flex;
    align-items: center;
    cursor: move;
}

.divider {
    width: 25px;
    height: 0px;
    border: 1px solid #666666;
    transform: rotate(90deg);
    flex: none;
    flex-grow: 0;
}

.attribute-info {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 15rem;
}

.attribute-label {
    /* color: #666666; */
}

.action-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
}

.disabled-icon {
    cursor: not-allowed;
    color: #B0B0B0;
}

.enabled-icon {
    cursor: pointer;
    color: purple;
}