body {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

th,
td {
  border-right: 2px solid var(--greys-03, #b3b3b3);
  border-bottom: none;
}

table th:last-child,
td:last-child {
  border-right: none;
}

option {
  height: 2px;
  overflow: auto;
}

.react-select {
  margin-top: 5px;
  border-radius: 8px !important;
  border: none !important;
  box-shadow: none !important;
}

.sender-id-modal {
  max-width: 50%;
  max-height: 90vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
}

.verythinscrollnewinv::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.verythinscrollnewinv::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.verythinscrollnewzero::-webkit-scrollbar {
  width: 0;
  /* margin-block: 12px; */
  background-color: #fafafa;
}

.verythinscrollnewzero::-webkit-scrollbar-track {
  /* margin: 12px; */
  background-color: #fafafa;
}

.verythinscrollnewzero::-webkit-scrollbar-thumb {
  /* background:  #666666; */
  background: #e6e6e6;
  border-radius: 100vw;
  border: 0 solid #f5f5f5;
}

.verythinscrollnewinv::-webkit-scrollbar {
  width: 0.5em;
  /* margin-block: 12px; */
  background-color: #fafafa;
}

.verythinscrollnewinv::-webkit-scrollbar-track {
  /* margin: 12px; */
  background-color: #fafafa;
}

.verythinscrollnewinv::-webkit-scrollbar-thumb {
  /* background:  #666666; */
  /* background:  #E6E6E6; */
  background: #f5f5f5;
  border-radius: 100vw;
  border: 0.125em solid #f5f5f5;
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  left: 100%;
  top: 0;
  position: absolute;
  background-color: #f9f9f9;
  margin-top: 0;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  width: 110%;
}

/* Links inside the dropdown */
.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content div:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.gpt-bg {
  position: absolute;
  bottom: 12px;
  left: 90px;
  z-index: 1;
  height: 70px;
  width: 70px;
  opacity: 1;
  filter: contrast(1);
}

.gpt-gif {
  position: absolute;
  bottom: 30px;
  left: 90px;
  z-index: 1;
  height: 68px;
  width: 68px;
  opacity: 1;
}

.gptComponent {
  position: absolute;
  bottom: 2%;
  left: 100px;
  height: 96vh;
  background: var(--whites-white-01, #fff);
  /* // box-shadow: -5px 0px 9px 0px rgba(0, 0, 0, 0.25); */
  width: 30%;
  z-index: 3;
  overflow-y: auto;
  border: 1px solid #cccc;
  border-radius: 16px;
  scrollbar-width: thin;
  padding: 10px;
}

.generate-div {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--border-color-border-medium, #ccc);
  padding: 7px 8px;
  font-size: 14px;
  gap: 8px;
  width: 100% !important;
}

.generate-field {
  width: 100% !important;
  height: 40px !important;
  outline: none !important;
  overflow-y: auto;
  resize: none;
  scrollbar-width: thin;
  appearance: none;
  border: none;
  background: none;
  outline: none;
}

.generate-field:focus {
  height: 5rem !important;
}

.generate-field::placeholder {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  opacity: 1;
  color: #cccccc;
  align-self: flex-end;
  margin: 10px !important;
}

#gptToggle {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

#gptToggle:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.notification {
  cursor: pointer;

}

.notification:hover {
  scale: 1.1;
}