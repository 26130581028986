@use "../abstracts/colors" as colors;
@use '../abstracts/functions' as f;
@use '../layout/breakpoints' as b;

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nda_modal_container {
    @extend .Column;
    justify-content: flex-start;
    height: 80vh;
    width: min(90vw, f.rem(850));
    background-color: colors.$white01;
    border-radius: f.rem(16);
    z-index: 0;
    border: 1px solid colors.$borderwhite;
    padding-inline: f.rem(40);
    padding-block: f.rem(24);
    // overflow-y: auto;

    &__header {
        @extend .Row;
        width: 100%;
        margin-block-end: f.rem(20);

        h3 {
            color: colors.$black01;
            width: 100%;
            text-align: center;
        }
    }
    
    &__body {
        @extend .Column;
        justify-content: flex-start;
        width: 100%;
        margin-block-end: f.rem(20);
        flex: 1;
        overflow-y: auto;

        p {
            color: colors.$black01;
            width: 100%;
        }
    }
    
}