.ContactModuleDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 37px 40px 40px 40px;

  .tableDiv {
    //flex: 1;
    background: red;
    margin-top: 13px;
    border-radius: 16px;
    border: 1px solid var(--whites-04, #f0f0f0);
    background: var(--whites-01, #fff);
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    height: calc(100% - 30px);
  }

  .commonDiv {
    display: flex;
    justify-content: space-between;

    .UploadContactButton {
      display: flex;
      appearance: none;
      align-items: center;
      border-radius: var(--corner-radius-corner-radius-s, 10px);
      background: var(--blacks-black-01, #000);
      border: none;
      color: #ffffff;
      padding: 8px 16px;
      gap: 5px;
      font-size: 14px;
    }

    .loggedInput {
      display: flex;
      align-items: center;
      border-radius: 10px;
      border: 1px solid var(--border-color-border-medium, #ccc);
      padding: 7px 8px;
      font-size: 14px;
      gap: 8px;

      input {
        appearance: none;
        border: none;
        background: none;
        padding: 0;
        outline: none;
      }
    }
  }
}

.pageDropdownSelectDiv {
  display: flex;
  align-items: center;

  .pageDropdownSelect {
    appearance: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0 0 0 0;
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    width: max-content;
    text-align: center;
    text-transform: capitalize;
    outline: none;
  }
}

.reportGenerateButton {
  background-color: #000000;
  border: none;
  border-radius: 8px;
  padding: 0px 10px;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.fbModal {
  min-width: 60%;
}

.contactModal {
  min-width: 60%;
}
