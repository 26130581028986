.ManageProfileBox {
    background-color: '#FAFAFA';
    height: 100vh;

    .BoxColumn1 {
        border: 1px solid #E6E6E6;
        width: 240px;
        height: 100%;
        justify-content: flex-start;

        .SidemenuHeader {
            height: 60px;
            width: 100%;
            border-bottom: 1px solid #E6E6E6;
            padding-inline: 16px;
            justify-content: flex-start;

            .IconStyle {
                height: 20px;
                width: 20px;
                object-fit: contain;
                cursor: pointer;
            }

            .TextStyle {
                padding: 0;
                margin: 0;
                padding-inline-start: 16px;
                font-size: 18px;
                font-weight: 700;
                color: black;
            }
        }

        .SidemenuItem {
            height: 60px;
            width: 100%;
            padding-inline: 16px;
            padding-block: 8px;
            cursor: pointer;

            .InnerContainer {
                height: 100%;
                width: 100%;
                border-radius: 8px;
                justify-content: flex-start;
                padding-inline: 16px;
            }

            .InnerText {
                font-size: 16px;
                font-weight: 400;
                color: #262626;
                padding: 0;
                margin: 0;
                padding-inline-start: 16px;
            }

            .selectedItem {
                background-color: #ECE1FF;
            }

            .selectedText {
                color: #5A3897;
            }
        }

        .SidemenuItem:hover {
            background-color: rgba(245, 245, 245, 0.4)
        }
    }

    .BoxColumn2 {
        border: 1px solid #E6E6E6;
        width: calc(100% - 240px);
        height: 100%;
        justify-content: flex-start;

        .AccountDetailsHeader {
            height: 60px;
            width: 100%;
            border-bottom: 1px solid #E6E6E6;
            padding-inline: 30px;
            // justify-content: flex-start;

            .ADHeaderText {
                padding: 0;
                margin: 0;
                font-size: 24px;
                font-weight: 700;
                color: black;
            }
        }

        .ADScrollable {
            width: 100%;
            height: calc(100% - 56px);
            overflow-y: scroll !important;
            justify-content: flex-start;

            .ProfilePictureContainer {
                width: 100%;
                padding-inline: 50px;
                padding-block: 50px;
                justify-content: flex-start;

                .ProfilePicStyle {
                    height: 140px;
                    width: 140px;
                    object-fit: contain;
                    border-radius: 50%;
                    border: 1px solid #E6E6E6;
                    // cursor: pointer;
                }
            }

            .RoleContainer {
                // margin-inline: 30px;
                border-radius: 12px;
                padding-block: 6px;
                padding-inline: 16px;
                margin-left: 50px;
                margin-right: auto;

                .RoleText {
                    padding: 0;
                    margin: 0;
                    font-size: 14px;
                    font-weight: 400;
                    color: white;
                }
            }
        }

        .PeopleHeader {
            height: 60px;
            width: 100%;
            border-bottom: 1px solid #E6E6E6;
            padding-inline: 30px;

            .PeopleHeaderText {
                padding: 0;
                margin: 0;
                font-size: 24px;
                font-weight: 700;
                color: black;
            }
        }

        .PeopleBody {
            width: 100%;
            padding-inline: 30px;
            margin-block: 20px;

            .PeopleBodyText {
                padding: 0;
                margin: 0;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
            }
        }
    }
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.APModalContainer {
    width: 1000px;
    background-color: white;
    border: 1px solid #E6E6E6;
    padding-block: 30px;
    padding-inline: 40px;
    border-radius: 16px;
    height: 80vh;

    .APMHeader {
        padding-block-end: 16px;
        border-bottom: 1px solid #E6E6E6;
        width: 100%;

        h2 {
            font-size: 24px;
            font-weight: 700;
            color: black;
            padding: 0;
            margin: 0;
        }

        img {
            height: 24px;
            width: 24px;
            object-fit: contain;
            cursor: pointer;
        }
    }
}

.NewApiKeyModal {
    margin-top: 10vh;
    border-radius: 18px;
}


.ButtonContainer {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 10px;
    background-color: white;

    .CustomButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10vw;
        padding: 10px;
        background-color: transparent;
        border: 1px solid #000;
        font-size: 16px;
        font-weight: 600;
        border-radius: 10px;
        cursor: pointer;

        img {
            margin-right: 5px;
        }

        @media (max-width: 1200px) {
            width: 12vw;
        }
    }
}