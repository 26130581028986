@use "../abstracts/colors" as colors;
.ModalDarkBg {
    background-color: rgba(0,0,0,0.5);
    width: 100vw;
    height: 100vh;
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;

    .Center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.hoverPrimary:hover {
    background-color: colors.$primary00;
    cursor: pointer;
}

.hoverSeqSidebar {
    background-color: #FAFAFA;
}
.hoverSeqSidebar:hover {
    background-color: #F5F5F5;
    cursor: pointer;
}